import Typography from "app/shared/Typography";
import "./OnboardingLandingDash.scss";
import { colorPicker } from "app/utils/color.helper";
import OTP from "antd/es/input/OTP";
import {
  Button,
  CountdownProps,
  Input,
  Modal,
  Progress,
  Segmented,
  Statistic,
  StatisticProps,
} from "antd";
import poweredFinkraft from "static/images/poweredFinkraft.png";
import { useEffect, useState } from "react";
import CountUp from "react-countup";
import OnboardAirlineReconView from "app/scenes/AppTour/components/OnboardAirlineReconView";
import { PlusOutlined, InfoCircleOutlined } from "@ant-design/icons";
import OnboardingHeader from "../OnboardingHeader";
import OnboardingHelpCenter from "../OnboardingHelpCenter";
import { ResponsivePie } from "@nivo/pie";
import { useRecoilState } from "recoil";
import { onboardInfo, userInfo } from "app/config/States/users";
import moment from "moment";
import UnReconciledFlight from "app/scenes/AppTour/components/OnboardUnReconcilied/UnReconciledFlight";
import UnReconciledHotel from "app/scenes/AppTour/components/OnboardUnReconcilied/UnReconciledHotel";
import { useNavigate } from "react-router-dom";
import { apiGet } from "app/services/apiServices";
import { API_ENDPOINT_FETCH_GST_CREDENTIALS_LIST } from "app/scenes/Credentials/credentials.constants";
import { AgTableClient } from "app/shared/AgTable";
const formatter = (value: any) => {
  return new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 0, // Ensure no decimal places are shown
    maximumFractionDigits: 0,
  }).format(value);
};

const { Countdown } = Statistic;

export default function OnboardingLandingDash(props: any) {
  const [activeScreen, setActiveScreen] = useState(0);
  const [userDetails, setUserDetails] = useRecoilState<any>(userInfo);
  const [dataType, setDataType] = useState("Flight");
  const [userOnboardInfo, setOnboardInfo] = useRecoilState<any>(onboardInfo);
  const [gstCreds, setGSTCreds] = useState([]);
  const deadline = new Date().getTime() + 1000 * 60 * 60 * 24 * 90; // 2 days from now
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const onFinish: CountdownProps["onFinish"] = () => {
    console.log("finished!");
  };

  useEffect(() => {
    fetchGSTCredentials();
  }, []);

  const fetchGSTCredentials = async () => {
    const response = await apiGet(API_ENDPOINT_FETCH_GST_CREDENTIALS_LIST);
    if (response.status) {
      setGSTCreds(response.data?.credentials);
    }
  };

  console.log("userDetails", userDetails);

  const onChange: CountdownProps["onChange"] = (val) => {
    if (typeof val === "number" && 4.95 * 1000 < val && val < 5 * 1000) {
      console.log("changed!");
    }
  };

  const start = moment(userDetails?.meta?.onboardingat || moment());
  const end = moment();

  // Calculate the difference in milliseconds
  const duration = moment.duration(end.diff(start));

  // Convert the duration to hours
  const hours = duration.asHours();

  const loaderPerc = (hours > 8 ? 100 : (hours / 8) * 100).toFixed(2);

  return (
    <div className="BookingDataPreview">
      <div className="HeaderContainer">
        <OnboardingHeader
          title={userOnboardInfo?.currentWorkspaceName || "Test Workspace"}
          noShadow
        />
      </div>
      <div style={{ paddingLeft: 20, marginTop: 24 }}>
        <Segmented<string>
          options={["Flight", "Hotel"]}
          onChange={(value) => {
            setDataType(value); // string
          }}
        />
      </div>
      <div className="WrapperContainer">
        <div className="RowContainer">
          <div className="Column" style={{ width: "68%" }}>
            <Typography style={{ color: colorPicker("neutral.800") }}>
              Please wait while we fetch your data it might take upto{" "}
              <span style={{ color: colorPicker("primary.700") }}>8 hours</span>
            </Typography>
            <div
              style={{ display: "flex", alignItems: "center", marginTop: 12 }}
            >
              <Progress
                percent={parseInt(loaderPerc) || 0}
                strokeColor={colorPicker("primary.700")}
                strokeWidth={12}
              />
            </div>
          </div>

          <div className="Column" style={{ width: "30%" }}>
            <Typography
              style={{ color: colorPicker("neutral.800") }}
              variant="caption"
            >
              Added PAN
            </Typography>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => setShowModal(true)}
            >
              <Statistic
                value={gstCreds.length || 0}
                valueStyle={{
                  color: colorPicker("primary.700"),
                  fontSize: "28px", // Change the font size here
                  fontWeight: 600,
                }}
                formatter={(value: any) => (
                  <CountUp end={value as number} separator="," />
                )}
              />
            </div>
          </div>
        </div>
        <div className="RowContainer" style={{ flex: 1, marginTop: 14 }}>
          <div className="Column" style={{ width: "100%", height: "100%" }}>
            {dataType === "Flight" ? (
              <UnReconciledFlight singleWorkspace />
            ) : (
              <UnReconciledHotel singleWorkspace />
            )}
          </div>
        </div>
        <div
          className="RowContainer"
          style={{ display: "flex", marginTop: 12 }}
        >
          <div>
            <img
              style={{ widows: 172, height: 24 }}
              src={poweredFinkraft}
              alt="poweredLogo"
            />
          </div>

          <div style={{ marginLeft: 28 }}>
            <OnboardingHelpCenter />
          </div>
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              // alignItems: "center",
              marginLeft: 44,
            }}
          >
            <div>
              <Button
                icon={<PlusOutlined />}
                style={{ marginRight: 24 }}
                onClick={() => navigate("/onboarding2/invite_user")}
              >
                Invite User
              </Button>
              <Button
                icon={<PlusOutlined />}
                type="primary"
                onClick={() => navigate("/onboarding2/creds")}
              >
                Add GST No
              </Button>
            </div>
          </div>
        </div>
      </div>

      <Modal
        title="Total Added PAN"
        open={showModal}
        onCancel={() => setShowModal(false)}
        footer={false}
        width={500}
      >
        <div style={{ height: 300 }}>
          <AgTableClient
            // @ts-ignore
            rowData={gstCreds}
            hideToolbar
            autoResize
            columnDefs={[
              {
                headerName: "Username",
                field: "username",
                editable: true,
              },
              {
                headerName: "Password",
                field: "password",
                editable: true,
              },
            ]}
          />
        </div>
      </Modal>
    </div>
  );
}
