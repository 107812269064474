import { Modal, Tag } from "antd";
import Loader from "app/shared/Loader";
import Typography from "app/shared/Typography";
import React, { useEffect, useState } from "react";
import { API_ENDPOINT_FETCH_RECENT_EMAIL_ACTIVITY } from "../../hotel.booking.constants";
import { apiGet } from "app/services/apiServices";
import { colorPicker } from "app/utils/color.helper";
import DraftsOutlinedIcon from "@mui/icons-material/DraftsOutlined";
import UnsubscribeOutlinedIcon from "@mui/icons-material/UnsubscribeOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import HotelOutlinedIcon from "@mui/icons-material/HotelOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import moment from "moment";
import { TagsFilled } from "@ant-design/icons";
import "./ActivityList.scss"
export default function EmailActivityList(props: any) {
  const [emailActivity, setEmailActivity] = useState<any[]>([]);
  const [activityLoading, setActivityLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [filterApplied, setFilterApplied] = useState(false);

  useEffect(() => {
    fetchRecentActivity();
  }, []);
  const fetchRecentActivity = async () => {
    setActivityLoading(true);
    const response = await apiGet(API_ENDPOINT_FETCH_RECENT_EMAIL_ACTIVITY);
    if (response) {
      setEmailActivity(response.data);
    }
    setActivityLoading(false);
  };

  const deviceHeight = window.innerHeight;
  

  return (
  

        <div
          className="MailActivityContainer"
          style={{ height: deviceHeight - 40 }}
        >

          <Typography
            style={{
              marginTop: 12,
            }}
          >
            Recent Email Activities{" "}
            {filterApplied ? (
              <Tag
                color="red"
                style={{ fontSize: 10, cursor: "pointer" }}
                onClick={() => {
                  setFilterApplied(false);
                  props.clearFilters();
                }}
              >
                Clear Filters
              </Tag>
            ) : null}
          </Typography>

          <div className="ActivityList">
            {activityLoading ? (
              <Loader />
            ) : (
              emailActivity.map((activity: any, key: any) => {
                return (
                  <div
                    className="ActivityCard"
                    key={key}
                    onClick={() => {
                      props.applyFilter(activity);
                      setFilterApplied(true);
                    }}
                  >
                    <div className="TopContent">
                      <div
                        className="Left"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        {activity.event === "open" ? (
                          <>
                            <DraftsOutlinedIcon
                              style={{
                                fontSize: 16,
                                marginRight: 4,
                                color: colorPicker("primary.700"),
                              }}
                            />
                            <Typography
                              style={{
                                fontSize: 12,
                                color: colorPicker("primary.700"),
                              }}
                            >
                              Opened
                            </Typography>
                          </>
                        ) : activity.event === "unsubscribed" ? (
                          <>
                            <UnsubscribeOutlinedIcon
                              style={{
                                fontSize: 16,
                                marginRight: 4,
                                color: colorPicker("red.700"),
                              }}
                            />
                            <Typography
                              style={{
                                fontSize: 12,
                                color: colorPicker("red.700"),
                              }}
                            >
                              Unsubscribed
                            </Typography>
                          </>
                        ) : (
                          <>
                            <CancelOutlinedIcon
                              style={{
                                fontSize: 16,
                                marginRight: 4,
                                color: colorPicker("red.700"),
                              }}
                            />
                            <Typography
                              style={{
                                fontSize: 12,
                                color: colorPicker("red.700"),
                              }}
                            >
                              Bounced/Dropped
                            </Typography>
                          </>
                        )}
                      </div>
                      <div
                        className="Read"
                        style={{ fontSize: 12, color: "#00000087" }}
                      >
                        {moment(activity.timestamp * 1000).fromNow()}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: 6,
                      }}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <PersonOutlineOutlinedIcon style={{ fontSize: 18 }} />
                        <Typography
                          variant="caption"
                          style={{ color: colorPicker("neutral.900") }}
                        >
                          {activity.email}
                        </Typography>
                      </div>
                      <div
                        style={{
                          marginTop: 6,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <HotelOutlinedIcon
                          style={{ fontSize: 18, marginRight: 5 }}
                        />
                        <Typography
                          variant="caption"
                          style={{ color: colorPicker("neutral.900") }}
                          weight={600}
                        >
                          {
                            activity.log?.dynamicTemplateData?.hotel_details
                              ?.hotel_name
                          }
                        </Typography>
                      </div>
                      <div>
                        <Tag
                          color="cyan"
                          style={{ fontSize: 10 }}
                          icon={<TagsFilled />}
                        >
                          {activity?.log?.booking_record_locator}
                        </Tag>
                      </div>
                    </div>
                  </div>
                );
              })
            )}
          </div>

        </div>
      
  );
}
