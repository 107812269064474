import { saveAs } from "file-saver";
import PageHeader from "app/shared/PageHeader";
import "./HotelBookingView.scss";
import CustomReconTabs from "../Reconcilation/components/CustomReconTabs";
import { useEffect, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import { tabViewStates, userInfo } from "app/config/States/users";
import PendingIcon from "@mui/icons-material/Pending";
import DraftsOutlinedIcon from "@mui/icons-material/DraftsOutlined";
import MarkEmailReadOutlinedIcon from "@mui/icons-material/MarkEmailReadOutlined";
import AdsClickOutlinedIcon from "@mui/icons-material/AdsClickOutlined";
import PrivacyTipOutlinedIcon from "@mui/icons-material/PrivacyTipOutlined";
import UnsubscribeOutlinedIcon from "@mui/icons-material/UnsubscribeOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import WatchLaterOutlinedIcon from "@mui/icons-material/WatchLaterOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import DoneAllOutlinedIcon from "@mui/icons-material/DoneAllOutlined";
import MarkunreadMailboxOutlinedIcon from "@mui/icons-material/MarkunreadMailboxOutlined";
import ScheduleSendOutlinedIcon from "@mui/icons-material/ScheduleSendOutlined";
import { AgTableClient } from "app/shared/AgTable";
import { Button, Modal, Tag, Tooltip, message, Drawer, Input } from "antd";
import moment from "moment";
import html2canvas from "html2canvas";
import ToolParamInvoker from "app/shared/AgTable/HelperTools/ToolParamInvoker";

import {
  CloudUploadOutlined,
  PhoneFilled,
  MailOutlined,
  CheckCircleFilled,
  WarningFilled,
  PlusOutlined,
  InfoCircleOutlined,
  PercentageOutlined,
  MailFilled,
  EditFilled,
  EllipsisOutlined,
  CloseCircleOutlined,
  ClockCircleFilled,
  CopyOutlined,
  ExportOutlined,
  CalendarOutlined,
  TagsFilled,
  DownloadOutlined,
} from "@ant-design/icons";
import Typography from "app/shared/Typography";
import { EditOutlined, EmailOutlined } from "@mui/icons-material";
import HotelDataUpdate from "./components/HotelData";
import { apiGet, apiPost } from "app/services/apiServices";
import Handlebars from "handlebars";

import {
  API_ENDPOINT_FETCH_HOTEL_BOOKING_NEW,
  API_ENDPOINT_FETCH_RECENT_EMAIL_ACTIVITY,
  API_ENDPOINT_FETCH_TEMPLATE_INFO,
  API_ENDPOINT_GET_INVOICE_UPLOAD_LINK,
  API_ENDPOINT_TRIGGER_CUSTOM_MAIL,
  CUSTOM_MAIL_SEND_ACTIONS,
  API_ENDPOINT_POST_INVOICE_NUMBER,
} from "./hotel.booking.constants";
import { colorPicker } from "app/utils/color.helper";
import { handleCellRenderer } from "app/shared/AgTable/AgUtility";
import { FormattedNumber } from "react-intl";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import { hotelBookingActivityType, SINGLE_MAIL_DATA } from "./templateData";
import Loader from "app/shared/Loader";
import EmailActivityList from "./components/ActivityList";
import SearchInput from "app/shared/SearchInput";
import { filter, values } from "lodash";
import { AnyComponent } from "styled-components/dist/types";
import { DateFilter } from "ag-grid-enterprise";
import ColumnGroup from "antd/es/table/ColumnGroup";
import PDFViewerTool from "app/shared/AgTable/HelperTools/PDFViewerTool";
import { AnyObject } from "antd/es/_util/type";
import { AnyMxRecord } from "dns";

// import JSZip from 'jszip';
import { responsiveArray } from "antd/es/_util/responsiveObserver";



const modalTitleMap: any = {
  TEMPLATE_VIEW: "Template Preview",
  ADD_HOTEL: "Update Hotel Info",
  ADD_CC: "Add CC Emails",
};

const colorOfEmailEvent: any = {
  processed: colorPicker("primary.700"),
  delivered: colorPicker("primary.700"),
  open: colorPicker("primary.700"),
  click: colorPicker("primary.700"),
  bounce: "#e74c3c",
  dropped: "#e74c3c",
  spammed: "#e74c3c",
  deffered: "#e74c3c",
};
const EVENT_ICON_MAP = {
  processed: (color: any) => (
    <CheckOutlinedIcon
      style={{
        fontSize: 18,
        color: color,
        cursor: "pointer",
      }}
    />
  ),
  delivered: (color: any) => (
    <DoneAllOutlinedIcon
      style={{
        fontSize: 18,
        color: color,
        cursor: "pointer",
      }}
    />
  ),
  open: (color: any) => (
    <DraftsOutlinedIcon
      style={{
        fontSize: 18,
        color: color,
        cursor: "pointer",
      }}
    />
  ),
  click: (color: any) => (
    <AdsClickOutlinedIcon
      style={{
        fontSize: 18,
        color: color,
        cursor: "pointer",
      }}
    />
  ),
  acknowledge: (color: any) => (
    <AssignmentTurnedInIcon
      style={{
        fontSize: 18,
        color: color,
        cursor: "pointer",
        marginRight: 28,
      }}
    />
  ),

  UNSUBSCRIBE: (color: any) => (
    <UnsubscribeOutlinedIcon
      style={{
        fontSize: 18,
        color: color,
        cursor: "pointer",
      }}
    />
  ),
  SPAMMED: (color: any) => (
    <PrivacyTipOutlinedIcon
      style={{
        fontSize: 18,
        color: color,
        cursor: "pointer",
      }}
    />
  ),
  bounce: (color: any) => (
    <CancelOutlinedIcon
      style={{
        fontSize: 18,
        color: color,
        cursor: "pointer",
      }}
    />
  ),

  dropped: (color: any) => (
    <MarkunreadMailboxOutlinedIcon
      style={{
        fontSize: 18,
        color: color,
        cursor: "pointer",
      }}
    />
  ),

  DEFFERED: (color: any) => (
    <ScheduleSendOutlinedIcon
      style={{
        fontSize: 18,
        color: color,
        cursor: "pointer",
      }}
    />
  ),
};





function formatDate(timestamp: any) {
  // Convert Unix timestamp to milliseconds
  const date = new Date(timestamp * 1000);

  // Use Moment.js to format the date
  const formattedDate = moment(date).format("DD-MM-YYYY, HH:MM A");

  return formattedDate;
}

const isValidDate = (dateString: string) => {
  const date = new Date(dateString);
  return !isNaN(date.getTime());
};



const handleRenderEmailEvents = (eventData: any) => {
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        alignItems: "center",
      }}
    >
      {Object.entries(EVENT_ICON_MAP).map((emailEvent: any) => {
        const readers = eventData?.[emailEvent[0]] || [];

        return (
          <div
            key={emailEvent[0]}
            style={{
              marginRight: 6,
              display: "flex",
              height: "100%",
              alignItems: "center",
            }}
          >
            <Tooltip
              placement="bottom"
              title={
                <div>
                  <Typography
                    style={{ color: "white", marginBottom: 12 }}
                    variant="xs"
                  >
                    {emailEvent[0]}
                  </Typography>

                  {/* Full list of emails and timestamps */}
                  {readers.length > 0 && (
                    <Tag key={readers[0].email} style={{ fontSize: 10 }}>
                      {readers[0].email}
                      {readers.length > 1 && ` +${readers.length - 1} more`}
                    </Tag>
                  )}
                </div>
              }
            >
              {emailEvent[1](
                readers.length > 0
                  ? colorOfEmailEvent[emailEvent[0]]
                  : "rgb(192 194 197)"
              )}
            </Tooltip>

           
          </div>
        );
      })}
    </div>
  );
};


const emailSentStatusMap = [
  "BOOKING",
  "CHECKIN",
  "CHECKOUT_1",
  "CHECKOUT_2",
  "COMPLETED",
  "MONTH_END",
  "BEFORE_GSTR",
  "AFTER_GSTR",
];

const emailSentStatusTitle: any = {
  BOOKING: "Email for Booking Done",
  CHECKIN: "Email for check-in",
  CHECKOUT_1: "Email for checkout reminder 5 hours before",
  CHECKOUT_2: "Email for checkout reminder 2 hours before",
  COMPLETED: "Email to notify about checkout done",
  MONTH_END: "Month End Report Email",
  BEFORE_GSTR: "Email consolidated before GSTR",
  AFTER_GSTR: "Email consolidated after GSTR",
};
export default function HotelBookingView(props: any) {

  const [activeKey, setActiveKey] = useState(1);
  const [userDetails, _] = useRecoilState<any>(userInfo);
  const [showModal, setShowModal] = useState("");
  const [bookingList, setBookingList] =useState<any[]>([]); 
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [messageApi, contextHolder] = message.useMessage();
  const [templateData, setTemplateData] = useState(null);
  const [templateDataBulk, setTemplateDataBulk] = useState(null);
  const [viewTemplate, setViewTemplate] = useState<any>();
  const [templateRowData, setTemplateRowData] = useState<any>();




  const [isInvoiceModalVisible, setIsInvoiceModalVisible] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [invoiceNumber, setInvoiceNumber] = useState('');





  const [filterApplied, setFilterApplied] = useState(false);
  const [open, setOpen] = useState(true);
  const [pdfViewerData, setPdfViewerData] = useState("");




  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    fetchTemplateInfo();
    fetchTemplateInfoBulk();
  }, []);

  const fetchTemplateInfo = async () => {
    const response = await apiGet(
      API_ENDPOINT_FETCH_TEMPLATE_INFO + "d-8f0cc8424e764423941ea0af6f179758"
    );
    if (response) {
      setTemplateData(response.html_content);
    }
  };

  const fetchTemplateInfoBulk = async () => {
    const response = await apiGet(
      API_ENDPOINT_FETCH_TEMPLATE_INFO + "d-a4a07a17c35d4b96ac6e89c84ad3463c"
    );
    if (response) {
      setTemplateDataBulk(response.html_content);
    }
  };

  const getRedFlag = (rowData: any) => {
    if (
      !rowData?.hotel_details ||
      !rowData?.hotel_details?.hotel_emails ||
      rowData?.hotel_details?.hotel_emails?.length === 0
    ) {
      return (
        <>
          <WarningFilled style={{ marginRight: 6, color: "#e74c3c" }} />
          <Typography variant="xs">No Recipients</Typography>
        </>
      );
    } else if (rowData?.email_events?.BOOKING?.dropped?.length > 0) {
      return (
        <>
          <WarningFilled style={{ marginRight: 6, color: "#e74c3c" }} />
          <Typography variant="xs">Mail Dropped</Typography>
        </>
      );
    } else if (rowData?.email_events?.BOOKING?.bounce?.length > 0) {
      return (
        <>
          <WarningFilled style={{ marginRight: 6, color: "#e74c3c" }} />
          <Typography variant="xs">Bounced</Typography>
        </>
      );
    } else {
      return (
        <>
          <CheckCircleFilled style={{ marginRight: 6, color: "#27ae60" }} />
          <Typography variant="xs">Running</Typography>
        </>
      );
    }
  };



  const getGSTCalculated = (amount: any) => {
    let parsedAmount = parseFloat(amount);
    if (parsedAmount < 1000) {
      return 0;
    } else if (parsedAmount < 7500) {
      return (parsedAmount / 100) * 12;
    } else {
      return (parsedAmount / 100) * 18;
    }
  };
  const handleTriggerMail = async (rowData: any) => {
    const response = await apiPost(API_ENDPOINT_TRIGGER_CUSTOM_MAIL, rowData);
    if (response.status) {
      messageApi.success({
        type: "success",
        content: "Email Sent!",
      });
      // props.onSuccess();
    } else {
      messageApi.error({
        type: "error",
        content: "Could not updated hotel detail",
      });
    }
  };

  const templateViewMap: any = {
    0: {
      title: "Booking Data Template",
      data: templateData,
      json: SINGLE_MAIL_DATA,
    },
    1: {
      title: "Checkin Email Template",
      data: templateData,
      json: SINGLE_MAIL_DATA,
    },
    2: {
      title: "Checkout Data Template",
      data: templateData,
      json: SINGLE_MAIL_DATA,
    },
    3: {
      title: "Monthly Report Template",
      data: templateDataBulk,
      json: SINGLE_MAIL_DATA,
    },
    4: {
      title: "Before GSTR Template",
      data: templateDataBulk,
      json: SINGLE_MAIL_DATA,
    },
    5: {
      title: "After GSTR Template",
      data: templateDataBulk,
      json: SINGLE_MAIL_DATA,
    },
  };

  const handleOpenInvoices = (links: any) => {
    const urlArray = Array.isArray(links) ? links : [links];
    urlArray.forEach((element: string) => {
      if (typeof element === "string" && element.trim() !== "") {
        window.open(element, "_blank");
      } else {
        console.error("Invalid link:", element);
      }
    });
  };

  const getValueOfMailStatus = (data: any) => {
    if (data?.email_events?.BOOKING?.dropped?.length > 0) {
      return "Dropped";
    }
    if (data?.email_events?.BOOKING?.bounce?.length > 0) {
      return "Bounce";
    }

    if (
      data?.hotel_details &&
      data?.hotel_details?.hotel_emails &&
      data?.hotel_details?.hotel_emails?.length > 0
    ) {
      return "Running";
    }
    console.log("bounce or dropped", data?.email_events);

    return "No Recipients";
  };

  // const handleCopyData = (copyText: any) => {
  //   if (navigator.clipboard) {
  //     navigator.clipboard
  //       .writeText(copyText)
  //       .then(() => {
  //         console.log("Text copied to clipboard");
  //         messageApi.success({
  //           type: "success",
  //           content: "Copied",
  //         });
  //       })
  //       .catch((err) => {
  //         console.error("Failed to copy text: ", err);
  //       });
  //   } else {
  //     // Clipboard API not supported, fallback to older method
  //     fallbackCopyTextToClipboard(copyText);
  //   }
  // };

  const handleCopyData = (copyText: any, isDate: boolean = false) => {
    let textToCopy = copyText;
    if (isDate) {
      textToCopy = moment(copyText).format("DD-MM-YYYY");
      console.log(textToCopy, "Text to copy")
    }
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(textToCopy)
        .then(() => {
          console.log("Text copied to clipboard");
          messageApi.success({
            type: "success",
            content: "Copied",
          });
        })
        .catch((err) => {
          console.error("Failed to copy text: ", err);
        });
    } else {
      // Clipboard API not supported, fallback to older method
      fallbackCopyTextToClipboard(textToCopy);
    }
  };



  function fallbackCopyTextToClipboard(text: any) {
    // Create a temporary textarea element
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();

    try {
      // Execute the copy command
      document.execCommand("copy");
      console.log("Text copied to clipboard");
      messageApi.success({
        type: "success",
        content: "Copied",
      });
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }

    // Remove the temporary textarea element
    document.body.removeChild(textArea);
  }

  const handleOpenUploadLink = async (bookingData: any) => {
    console.log(bookingData.data.recordLocator, "response in upload link")
    const response = await apiGet(
      API_ENDPOINT_GET_INVOICE_UPLOAD_LINK +
      "?recordLocator=" +
      bookingData?.data.recordLocator
    );
   
   
    if (response.status) {
      window.open(response.data.url, "_blank");
    } else {
      messageApi.error({
        type: "error",
        content: "Could not generate upload link",
      });
    }
  };
  const handleButtonClick = (params: any) => {
    console.log("params inside handle button click", params);
    setSelectedInvoice(params.data);
    setInvoiceNumber(params.data.hotelInvConfNo || '');
    setIsInvoiceModalVisible(true);
  };

  const handleSave = async () => {
    const invoice = selectedInvoice as { recordLocator: string } | null; // Type assertion

    if (invoice) {
      console.log(invoice.recordLocator, "selected invoice in handle save");

      const dataToPost = {
        recordLocator: invoice.recordLocator,
        hotelInvConfNo: invoiceNumber
      };

      try {
        const response = await apiPost(API_ENDPOINT_POST_INVOICE_NUMBER, dataToPost);
        if (response.status) {
          messageApi.success({
            type: "success",
            content: "Invoice number saved successfully",
          });

          setIsInvoiceModalVisible(false);
          setSelectedInvoice(null);
        } else {
          messageApi.error({
            type: "error",
            content: "Could not save the invoice number",
          });
        }
      } catch (error) {
        messageApi.error({
          type: "error",
          content: "An error occurred while saving the invoice number",
        });
        console.error('Error saving the invoice number:', error);
      }
    } else {
      console.error('selectedInvoice is null');
      messageApi.error({
        type: "error",
        content: "No invoice selected",
      });
    }
  };


  function customAggregation(aggParams: any) {
    let hasLinks = false;

    // Check if any group item has links
    aggParams.values.forEach((value: any) => {
      if (value && value.length > 0) {
        hasLinks = true;
      }
    });

    // Return 'Available' if any item has links, otherwise 'Pending'
    return hasLinks ? "Available" : "Pending";
  }




  const handleCancel = () => {
    setIsInvoiceModalVisible(false);
  };

  const openSidebarRenderer = (params: any) => {
    console.log("console.log inside side bar renderer", params);
    setPdfViewerData(params?.data?.invoice_link);
    params.api.openToolPanel("pdfRenderer", { paramData: params });
  };

//   const handleDownloadGroupedInvoices = (allLeafChildren:any) => {
//     const invoiceLinks:any = [];
    
//     allLeafChildren.forEach((linkObj:any, index:any) => {
//         const links = linkObj?.data?.invoice_link || [];
//         console.log(`Invoice ${index + 1} data:`, linkObj);
//         console.log(`Extracted links:`, links);
        
//         if (Array.isArray(links) && links.length > 0) {
//             invoiceLinks.push(...links);
//         }
//     });

//     console.log("Final invoiceLinks to zip:", invoiceLinks);

//     if (invoiceLinks.length === 0) {
//         console.warn("No invoice links found for download.");
//         return;
//     }

//     // Create a ZIP file
//     // const zip = new JSZip();
    
//     const invoicePromises = invoiceLinks.map((link:any, index:any) => {
//         console.log(`Attempting to fetch invoice: ${link}`);
//         return fetch(link,{mode:"cors"} )
//             .then((response) => {
//                 if (response.ok) {
//                     console.log(`Successfully fetched invoice: ${link}`);
//                     return response.blob();
//                 } else {
//                     console.error(`Error fetching invoice: ${link}`, response.status, response.statusText);
//                     throw new Error(`Failed to fetch invoice: ${link}`);
//                 }
//             })
//             .then((blob) => {
//                 zip.file(`invoice_${index + 1}.pdf`, blob);
//             })
//             .catch((error) => {
//                 console.error(`Error processing invoice link: ${link}`, error);
//             });
//     });

//     Promise.all(invoicePromises).then(() => {
//         zip.generateAsync({ type: "blob" }).then((content:any) => {
//             console.log("Generated ZIP file size:", content.size);
//             saveAs(content, "invoices.zip");
//         });
//     });
// };





  const initialColDef = [

    
    {
      headerName: "Sl. No.",
      valueGetter: (params: any) => {
        return params.node.rowIndex + 1;
      },
      enableRowGroup: false,
      hideColumnInGroup: true,
      width: 95,
    },

    {
      headerName: "Mail Config Status",
      field: "email_events",
      width: 200,
      enableRowGroup: true,
      // formatType: "CUSTOM_RENDER",

      cellRenderer: (params: any) => {
        console.log("data is :", params);
        return (
          <div
            style={{
              height: "100%",
              width: "100%",
              display: "flex",
              alignItems: "center",
            }}
          >
            {getRedFlag(params?.data)}
          </div>
        );
        return "test";
      },
      valueGetter: (params: any) => {
        // console.log("value data:", params.data?.email_events);
        return getValueOfMailStatus(params?.data);
        return "Test";
      },
      formatProps: {
        valueGetterData: (params: any, fullParams: any) =>
          getValueOfMailStatus(params?.data),

        // valueGetterData: (params: any) => getValueOfMailStatus(params),
      },
    },
    {
      field: "from",
      headerName: "Time Left For Checkout",
      width: 200,
      enableRowGroup: true,
      formatType: "CUSTOM_RENDER",
      formatProps: {
        cellRenderer: (data: any, params: any) => {
          let momentDays = moment.duration(
            moment(params?.booking_data?.tripDetails?.tripEndDateTime).diff(
              moment().format("YYYY-MM-DDTHH:mm:ssZ")
            )
          );
          const isCheckedOut = moment().isAfter(
            moment(params?.booking_data?.tripDetails?.tripEndDateTime)
          );
          const isEmailSent =
            Object.keys(params?.email_events || {})?.length > 0;
          const days = Math.floor(momentDays.asDays());
          const hours = Math.floor(momentDays.asHours() % 24);
          const totalHours = Math.floor(momentDays.asHours());

          return (
            <div
              style={{
                // background: hours > 10 || days > 0 ? "green" : "red",
                padding: "0 16px",
              }}
            >
              <Tag
                color={
                  isEmailSent ? "green" : totalHours < 20 ? "red" : "green"
                }
                icon={
                  isCheckedOut ? <CheckCircleFilled /> : <ClockCircleFilled />
                }
              >
                {isCheckedOut
                  ? "Checked Out"
                  : `${days + 1} days ${hours} hours ${hours < 0 || days < 0 ? "passed" : "left"
                  }`}
              </Tag>
            </div>
          );
        },

        // valueGetterData: (params: any) => {
        //   let momentDays = moment.duration(
        //     moment(params?.booking_data?.tripDetails?.tripEndDateTime).diff(
        //       moment(moment().format("YYYY-MM-DDTHH:mm:ssZ"))
        //     )
        //   );
        //   return momentDays.asHours();
        // },
        valueGetterData: (params: any) => {
          let momentDays = moment.duration(
            moment(params?.booking_data?.tripDetails?.tripEndDateTime).diff(
              moment(moment().format("YYYY-MM-DDTHH:mm:ssZ"))
            )
          );
          const days = Math.floor(momentDays.asDays());
          const hours = Math.floor(momentDays.asHours() % 24);
          const isCheckedOut = moment().isAfter(
            moment(params?.booking_data?.tripDetails?.tripEndDateTime)
          );

          if (isCheckedOut) {
            return "Checked Out";
          } else {
            return `${days + 1} days ${hours} hours ${hours < 0 || days < 0 ? "passed" : "left"
              }`;
          }
        },
      },
    },
    // {
    //   field: "lastmail",
    //   headerName: "Last Mail Sent",
    //   cellRenderer: (params: any) => {
    //     return moment().format("DD-MM-YYYY, HH:MM A");
    //   },
    // },

    {
      field: "booking_data",
      headerName: "Template",
      width: 200,
      formatType: "CUSTOM_RENDER",
      exportIgnore: true,
      formatProps: {
        cellRenderer: (data: any, params: any) => {
          return (
            <div
              style={{
                height: "100%",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {[0, 1, 2, 3, 4, 5].map((template, key) => {
                return (
                  <div
                    style={{
                      width: 18,
                      height: 18,
                      background: "#34495e",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "50%",
                      color: "white",
                      cursor: "pointer",
                      marginRight: 8,
                    }}
                    onClick={() => {
                      setShowModal("TEMPLATE_VIEW");
                      setViewTemplate(template);
                      setTemplateRowData(params);
                    }}
                    key={key}
                  >
                    {template + 1}
                  </div>
                );
              })}
            </div>
          );
        },
        valueGetterData: (params: any) => params,
      },
    },
    {
      headerName: "Hotel Details",
      children: [
        {
          headerName: "Hotel Contact",
          formatType: "CUSTOM_RENDER",
          ...handleCellRenderer(),
          exportIgnore: true,
          formatProps: {
            cellRenderer: (data: any, params: any) => {
              let hotelInfo = params?.hotel_details;
              return (
                <div
                  style={{
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                  }}
                  onClick={() => {
                    setSelectedBooking({
                      ...params,
                      hotel_code:
                        params?.booking_data?.segments?.property?.code,
                    });
                    setShowModal("ADD_HOTEL");
                  }}
                >
                  {/* <MailFilled
                    style={{ marginRight: 10, color: colorPicker("green.700") }}
                  /> */}
                  <MailOutlined
                    style={{
                      marginRight: 10,
                      color: colorPicker(
                        !hotelInfo?.hotel_emails ||
                          hotelInfo?.hotel_emails.length === 0
                          ? "red.700"
                          : "green.700"
                      ),
                    }}
                  />
                  <PhoneFilled
                    style={{
                      marginRight: 10,
                      color: colorPicker(
                        hotelInfo?.hotel_phone?.length === 0
                          ? "red.700"
                          : "green.700"
                      ),
                    }}
                  />
                  <Typography
                    variant="xs"
                    weight={700}
                    style={{
                      fontSize: 11,
                      color: colorPicker(
                        !hotelInfo?.hotel_gstin ? "red.700" : "green.700"
                      ),
                    }}
                  >
                    GSTIN
                  </Typography>

                  <EditFilled style={{ marginLeft: 6, cursor: "pointer" }} />
                </div>
              );
            },
            valueGetterData: (params: any) => {
              return Array.isArray(Object.keys(params?.hotel_details || {}))
                ? Object.keys(params?.hotel_details || {})
                : [];
            },
          },
          filter: "agSetColumnFilter",
          filterParams: {
            values: ["hotel_gstin", "hotel_emails", "hotel_phone"],
            valueFormatter: (params: any) =>
              Array.isArray(params.value)
                ? params.value.join(", ")
                : params.value,
            comparator: (a: any, b: any) => a.localeCompare(b),
          },
        },

        {
          field: "hotel_details.hotel_name",
          headerName: "Hotel Name",
          width: 300,
          enableRowGroup: true,
          filter: "agTextColumnFilter",
          formatType: "CUSTOM_RENDER",
          ...handleCellRenderer(),
          formatProps: {
            // valueGetterData: (params: any) =>
            //   params.data?.hotel_details?.hotel_name,
            valueGetter: (params: any) =>
              params.data?.hotel_details?.hotel_name,
            cellRenderer: (data: any, params: any) => {
              return (
                <div style={{ display: "flex", alignItems: "center" }}>
                  {params?.hotel_details ? (
                    <>
                      <CopyOutlined
                        style={{ marginRight: 6, cursor: "pointer" }}
                        onClick={() =>
                          handleCopyData(params?.hotel_details?.hotel_name)
                        }
                      />
                      <Typography variant="xs" style={{ marginRight: 12 }}>
                        {params?.hotel_details?.hotel_name}
                      </Typography>
                    </>
                  ) : (
                    <Tag
                      icon={<InfoCircleOutlined />}
                      color="red"
                      style={{ cursor: "pointer" }}
                    >
                      Add Hotel Info
                    </Tag>
                  )}
                </div>
              );
            },
          },
          columnGroupShow: "close",
        },
        // {
        //   headerName: "Hotel GSTIN",
        //   field: "hotel_details.hotel_gstin",
        //   formatType: "CHIP",
        //   ...handleCellRenderer(),
        //   enableRowGroup: true,
        //   filter: "agTextColumnFilter",
        // },
        {
          headerName: "Hotel GSTIN",
          field: "hotel_details.hotel_gstin",
          formatType: "CHIP",
          enableRowGroup: true,
          filter: "agTextColumnFilter",
          cellRenderer: (params: any) => {
            if (params.data && params.data.hotel_details && params.data.hotel_details.hotel_gstin) {
              return (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <CopyOutlined
                    style={{ cursor: "pointer", fontSize: "15px" }}
                    onClick={() => handleCopyData(params.data.hotel_details.hotel_gstin)}
                  />
                  <span style={{ marginLeft: '6px' }}>
                    {params.data.hotel_details.hotel_gstin}
                  </span>

                </div>
              );
            } else {
              return (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span style={{ marginRight: '10px' }}>
                    NA
                  </span>
                </div>
              );
            }
          },
        },
        {
          field: "from",
          headerName: "From",
          exportIgnore: true,
          width: 200,
          valueGetter: () => "gstinfo@finkraft.ai",
          cellRenderer: () => "gstinfo@finkraft.ai",
          columnGroupShow: "open",
          filter: "agTextColumnFilter",
        },
        {
          field: "to",
          headerName: "Email to hotel",
          width: 200,
          columnGroupShow: "open",
          formatType: "CUSTOM_RENDER",
          filter: "agTextColumnFilter",
          ...handleCellRenderer(),
          valueGetter: (params: any) =>
            params.data?.hotel_details?.hotel_emails,

          formatProps: {
            cellRenderer: (data: any, params: any) => {
              return (
                <div
                  style={{
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {params?.hotel_details?.hotel_emails?.map(
                    (hotel_email: any) => {
                      return <Tag icon={<MailOutlined />}>{hotel_email}</Tag>;
                    }
                  )}
                </div>
              );
            },
          },
        },

        {
          field: "cc",
          headerName: "CC",
          width: 200,
          columnGroupShow: "open",
          formatType: "CUSTOM_RENDER",
          filter: "agTextColumnFilter",
          ...handleCellRenderer(),
          exportIgnore: true,
          valueGetter: (params: any) => params.data?.cc_emails,
          formatProps: {
            cellRenderer: (data: any, params: any) => {
              return (
                <div
                  style={{
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {params?.cc_emails?.map((hotel_email: any) => {
                    return <Tag icon={<MailOutlined />}>{hotel_email}</Tag>;
                  })}
                  <Tag
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setSelectedBooking(params);
                      setShowModal("ADD_CC");
                    }}
                  >
                    Add Email
                  </Tag>
                </div>
              );
            },
          },
        },

        {
          field: "booking_data.segments.property.address.address1",
          headerName: "Hotel Address",
          width: 200,
          columnGroupShow: "open",
          // formatType: "CUSTOM_RENDER",
          filter: "agTextColumnFilter",
          formatType: "CUSTOM_RENDER",
          ...handleCellRenderer(),
          formatProps: {
            valueGetterData: (params: any) =>
              // params.data?.hotel_details?.hotel_name,
              params?.booking_data?.segments.property.address.address1,
            cellRenderer: (data: any, params: any) => {
              return (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <>
                    <CopyOutlined
                      style={{ marginRight: 6, cursor: "pointer" }}
                      onClick={() =>
                        handleCopyData(
                          params?.booking_data?.segments.property.address
                            .address1
                        )
                      }
                    />
                    <Typography variant="xs" style={{ marginRight: 12 }}>
                      {params?.booking_data?.segments.property.address.address1}
                    </Typography>
                  </>
                </div>
              );
            },
          },
        },
        {
          field: "booking_data.segments.property.address.city.name",
          headerName: "Hotel City",
          width: 200,
          columnGroupShow: "open",
          // formatType: "CUSTOM_RENDER",
          ...handleCellRenderer(),
          filter: "agTextColumnFilter",
        },
        {
          field: "booking_data.segments.property.address.region.code",
          headerName: "Hotel State",
          width: 200,
          columnGroupShow: "open",
          filter: "agTextColumnFilter",
          ...handleCellRenderer(),
        },

      ],
    },

    {
      headerName: "Booking Details",
      children: [
        {
          field: "to",
          headerName: "Email Sent Status",
          width: 250,
          columnGroupShow: "close",
          formatType: "CUSTOM_RENDER",
          ...handleCellRenderer(),
          exportIgnore: true,
          filter: "agSetColumnFilter",
          filterParams: {
            values: emailSentStatusMap,
            valueFormatter: (params: any) =>
              Array.isArray(params.value)
                ? params.value.join(", ")
                : params.value,
            comparator: (a: any, b: any) => a.localeCompare(b),
          },

          valueFormatter: (params: any) =>
            Array.isArray(params.value)
              ? params.value.join(", ")
              : params.value,

          // valueFormatter: (params: any) => params.value.join(", "),
          formatProps: {
            cellRenderer: (data: any, params: any) => {
              console.log("emailSentStatusMap", params, data);
              return (
                <div
                  style={{
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {emailSentStatusMap.map((emailStatusType: any) => {
                    return (
                      <Tooltip
                        title={
                          <div>
                            {emailSentStatusTitle[emailStatusType]}
                            {params?.email_events?.[emailStatusType]?.[
                              "processed"
                            ]
                              ? " at " +
                              formatDate(
                                params?.email_events?.[emailStatusType]?.[
                                  "processed"
                                ]?.[0]?.timestamp
                              )
                              : ""}
                          </div>
                        }
                      >
                        {params?.email_events?.[emailStatusType] ? (
                          <CheckCircleFilled
                            style={{
                              fontSize: 17,
                              marginRight: 4,
                              color: "#27ae60",
                              cursor: "pointer",
                            }}
                          />
                        ) : (
                          <PendingIcon
                            style={{
                              fontSize: 18,
                              marginRight: 4,
                              color: "#f39c12",
                              cursor: "pointer",
                            }}
                          />
                        )}
                      </Tooltip>
                    );
                  })}
                </div>
              );
            },
            valueGetterData: (params: any) => {
              return Array.isArray(Object.keys(params?.email_events || {}))
                ? Object.keys(params?.email_events || {})
                : [];
            },
          },
        },

        {
          headerName: "Hotel confirmation number",
          width: 200,
          ColumnGroupShow: "close",
          valueGetter: (params: any) => {
            // return params.data.hotelInvConfNo;
            return params.data ? params.data.hotelInvConfNo : null;
          },
          filter: "agTextColumnFilter",
          cellRenderer: (params: any) => {
            if (params.data && params.data.hotelInvConfNo) {

              return <div style={{ display: "flex", justifyContent: "space-between" }}>
                <CopyOutlined
                  onClick={() => handleCopyData(params.data.hotelInvConfNo)}
                  style={{ cursor: "pointer", fontSize: "15px" }}
                />
                <span style={{ gap: '5px', display: 'flex', flexDirection: 'row' }}>
                  <div style={{ width: '135px' }}>
                    <Typography variant="xs" style={{ marginLeft: 6 }}>
                      {params.data.hotelInvConfNo}
                    </Typography>

                  </div>
                  <div style={{}}>
                    <EditOutlined
                      onClick={() => handleButtonClick(params)}
                      style={{ cursor: "pointer", fontSize: "15px", marginLeft: '10px' }}
                    /></div>

                </span>
              </div>
              //   <span>
              //   {params.data.hotelInvConfNo} 
              //   <EditOutlined 
              //     onClick={() => handleButtonClick(params)}
              //   />
              // </span>
            } else {
              return (
                <Button
                  color="primary"
                  onClick={() => handleButtonClick(params)}
                >
                  Edit
                </Button>
              );
            }

          },
          valueGetterData: (params: any) => {
            return params.data ? params.data.hotelInvConfNo : null;
          }
        },
        {
          field: "booking_data.segments.confirmationNumber",
          headerName: "Confirmation Number",
          columnGroupShow: "open",
          formatType: "CUSTOM_RENDER",
          filter: "agTextColumnFilter",
          ...handleCellRenderer(),
          valueGetter: (params: any) => {
            return params.data?.booking_data?.segments?.confirmationNumber || '';
          },
          formatProps: {
            cellRenderer: (data: any, params: any) => {
              return (
                <>
                  <CopyOutlined
                    style={{ marginRight: 6, cursor: "pointer" }}
                    onClick={() =>
                      handleCopyData(
                        params?.booking_data?.segments?.confirmationNumber
                      )
                    }
                  />
                  <Tag>{params?.booking_data?.segments?.confirmationNumber}</Tag>
                </>
              );
            },
          },

        },

        {
          field: "booking_data.identification.recordLocator",
          headerName: "Booking ID",
          columnGroupShow: "open",
          formatType: "CUSTOM_RENDER",
          filter: "agTextColumnFilter",
          ...handleCellRenderer(),
          formatProps: {
            cellRenderer: (data: any, params: any) => {
              return (
                <>
                  <CopyOutlined
                    style={{ marginRight: 6, cursor: "pointer" }}
                    onClick={() =>
                      handleCopyData(
                        params?.booking_data?.identification?.recordLocator
                      )
                    }
                  />

                  <Tag>
                    {params?.booking_data?.identification?.recordLocator}
                  </Tag>
                </>
              );
            },
          },
        },
        {
          field: "booking_data.tripDetails.bookingDateTime",
          headerName: "Booking Date",
          width: 200,
          columnGroupShow: "close",
          formatType: "CUSTOM_RENDER",
          ...handleCellRenderer(),
    
          dateFilterTable: true,
          filter: "agDateColumnFilter",
    
    
    
          formatProps: {
            cellRenderer: (data: any, params: any) => {
              const dateValue = params?.booking_data?.tripDetails?.bookingDateTime;
              const formattedDateCopy = moment(dateValue).format("YYYY-MM-DD"); 
              const formattedDateDisplay = moment(dateValue).format("YYYY-MM-DD"); 
              console.log(dateValue,"date value")
              // const formattedDate = moment(dateValue).format("YYYY-MM-DD");
              // console.log(formattedDate, "formatted date inside column")
    
              return (
                <>
                  <CopyOutlined
                    style={{ marginRight: 6, cursor: "pointer" }}
                    onClick={() =>
                      handleCopyData(formattedDateCopy, true)
                    }
                  />
                  <Tag icon={<CalendarOutlined />}>
                    {formattedDateDisplay}
                  </Tag>
    
                </>
              );
            },
          },
          filterParams: {
            comparator: (filterLocalDateAtMidnight: any, cellValue: any) => {
              const cellDate = moment(cellValue, "YYYY-MM-DD", true); 
              if (!cellDate.isValid()) {
                return -1;
              }
              const filterDate = moment(filterLocalDateAtMidnight).startOf('day');
              const cellTime = cellDate.startOf('day').valueOf(); // Convert to timestamp
              const filterTime = filterDate.valueOf(); // Convert to timestamp
    
              console.log("Filter Time:", filterTime, "Cell Time:", cellTime);
    
              if (cellTime === filterTime) {
                return 0;
              } else if (cellTime < filterTime) {
                return -1;
              } else {
                return 1;
              }
            },
            valueGetter: (params: any) => {
              const dateValue = params.data?.booking_data?.tripDetails?.bookingDateTime;
              console.log("Value Getter - Trip End Date Time:", dateValue);
              return dateValue ? new Date(dateValue) : null;
            },
          },
    
        },


        {
          field: "booking_data.tripDetails.tripStartDateTime",
          headerName: "Checkin Date",
          width: 200,
          columnGroupShow: "close",
          formatType: "CUSTOM_RENDER",
          ...handleCellRenderer(),
    
          dateFilterTable: true,
          filter: "agDateColumnFilter",
    
    
    
          formatProps: {
            cellRenderer: (data: any, params: any) => {
              const dateValue = params?.booking_data?.tripDetails?.tripStartDateTime;
              const formattedDateCopy = moment(dateValue).format("YYYY-MM-DD"); 
              const formattedDateDisplay = moment(dateValue).format("YYYY-MM-DD"); 
              console.log(dateValue,"date value")
              // const formattedDate = moment(dateValue).format("YYYY-MM-DD");
              // console.log(formattedDate, "formatted date inside column")
    
              return (
                <>
                  <CopyOutlined
                    style={{ marginRight: 6, cursor: "pointer" }}
                    onClick={() =>
                      handleCopyData(formattedDateCopy, true)
                    }
                  />
                  <Tag icon={<CalendarOutlined />}>
                    {formattedDateDisplay}
                  </Tag>
    
                </>
              );
            },
          },
          filterParams: {
            comparator: (filterLocalDateAtMidnight: any, cellValue: any) => {
              const cellDate = moment(cellValue, "YYYY-MM-DD", true); 
              if (!cellDate.isValid()) {
                return -1;
              }
              const filterDate = moment(filterLocalDateAtMidnight).startOf('day');
              const cellTime = cellDate.startOf('day').valueOf(); // Convert to timestamp
              const filterTime = filterDate.valueOf(); // Convert to timestamp
    
              console.log("Filter Time:", filterTime, "Cell Time:", cellTime);
    
              if (cellTime === filterTime) {
                return 0;
              } else if (cellTime < filterTime) {
                return -1;
              } else {
                return 1;
              }
            },
            valueGetter: (params: any) => {
              const dateValue = params.data?.booking_data?.tripDetails?.tripStartDateTime;
              console.log("Value Getter - Trip End Date Time:", dateValue);
              return dateValue ? new Date(dateValue) : null;
            },
          },
    
        },



        


        {
          field: "booking_data.tripDetails.tripEndDateTime",
          headerName: "Checkout Date & Time",
          width: 200,
          columnGroupShow: "close",
          formatType: "CUSTOM_RENDER",
          ...handleCellRenderer(),
    
          dateFilterTable: true,
          filter: "agDateColumnFilter",
    
    
    
          formatProps: {
            cellRenderer: (data: any, params: any) => {
              const dateValue = params?.booking_data?.tripDetails?.tripEndDateTime;
              const formattedDateCopy = moment(dateValue).format("YYYY-MM-DD"); 
              const formattedDateDisplay = moment(dateValue).format("YYYY-MM-DD"); 
              console.log(dateValue,"date value")
              // const formattedDate = moment(dateValue).format("YYYY-MM-DD");
              // console.log(formattedDate, "formatted date inside column")
    
              return (
                <>
                  <CopyOutlined
                    style={{ marginRight: 6, cursor: "pointer" }}
                    onClick={() =>
                      handleCopyData(formattedDateCopy, true)
                    }
                  />
                  <Tag icon={<CalendarOutlined />}>
                    {formattedDateDisplay}
                  </Tag>
    
                </>
              );
            },
          },
          filterParams: {
            comparator: (filterLocalDateAtMidnight: any, cellValue: any) => {
              const cellDate = moment(cellValue, "YYYY-MM-DD", true); 
              if (!cellDate.isValid()) {
                return -1;
              }
              const filterDate = moment(filterLocalDateAtMidnight).startOf('day');
              const cellTime = cellDate.startOf('day').valueOf(); // Convert to timestamp
              const filterTime = filterDate.valueOf(); // Convert to timestamp
    
              console.log("Filter Time:", filterTime, "Cell Time:", cellTime);
    
              if (cellTime === filterTime) {
                return 0;
              } else if (cellTime < filterTime) {
                return -1;
              } else {
                return 1;
              }
            },
            valueGetter: (params: any) => {
              const dateValue = params.data?.booking_data?.tripDetails?.tripEndDateTime;
              console.log("Value Getter - Trip End Date Time:", dateValue);
              return dateValue ? new Date(dateValue) : null;
            },
          },
    
        },



        {
          field: "travellers",
          headerName: "Travellers",
          filter: "agTextColumnFilter",
          width: 300,
          columnGroupShow: "open",
          formatType: "CUSTOM_RENDER",
          ...handleCellRenderer(),
          valueGetter: (params: any) => {
            return params?.data?.booking_data?.travelers
              ?.map((traveler: any) => {
                const { firstName, middleName, lastName } =
                  traveler?.name || {};
                return `${middleName || ""} ${firstName || ""} ${lastName || ""
                  }`.trim();
              })
              .join(", ");
          },
          formatProps: {
            cellRenderer: (data: any, params: any) => {
              const travellerName = params?.booking_data?.travelers
                ?.map((traveler: any) => {
                  const { firstName, middleName, lastName } =
                    traveler?.name || {};
                  return `${middleName || ""} ${firstName || ""} ${lastName || ""
                    }`.trim();
                })
                .join(", ");
              return (
                <>
                  <CopyOutlined
                    style={{ marginRight: 6, cursor: "pointer" }}
                    onClick={() =>
                      handleCopyData(travellerName)
                    }
                  />

                  <div
                    style={{
                      height: "100%",
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {params?.booking_data?.travelers?.map((traveler: any) => {
                      return (
                        <Tag>{`${traveler?.name.middleName} ${traveler?.name.firstName} ${traveler?.name.lastName}`}</Tag>
                      );
                    })}
                  </div>
                </>
              );
            },
          },
        },


      ],
    },

    {
      headerName: "Customer Details",
      children: [

        {
          field: "gstin_detail.company_name",
          headerName: "Mail Sent To Traveller",
          enableRowGroup: true,
          columnGroupShow: "close",
          formatType: "CUSTOM_RENDER",
          ...handleCellRenderer(),
          formatProps: {
            cellRenderer: (data: any, params: any) => {
              let travellerEmail =
                params?.booking_data?.contacts?.emailAddresses?.[0]?.emailAddress;
              let sentEmails =
                params?.email_events?.CHECKOUT_2?.delivered?.map(
                  (emails: any) => emails.email
                ) || [];

              // Debugging: Log values
              console.log("Traveller Email:", travellerEmail);
              console.log("Sent Emails:", sentEmails);

              // Ensure all comparisons are case-insensitive
              const emailExists =
                travellerEmail &&
                sentEmails.some(
                  (sentEmail: any) =>
                    sentEmail?.toLowerCase() === travellerEmail?.toLowerCase()
                );

              return (
                <Tag
                  icon={emailExists ? <CheckCircleFilled /> : <CloseCircleOutlined />}
                  color={emailExists ? "green" : "red"}
                >
                  {emailExists ? "Yes" : "No"}
                </Tag>
              );
            },
            valueGetterData: (data: any) => {
              let travellerEmail =
                data?.booking_data?.contacts?.emailAddresses?.[0]?.emailAddress;
              let sentEmails =
                data?.email_events?.CHECKOUT_2?.delivered?.map(
                  (emails: any) => emails.email
                ) || [];

              // Debugging: Log values
              // console.log("Traveller Email (Value Getter):", travellerEmail);
              // console.log("Sent Emails (Value Getter):", sentEmails);

              // Ensure case-insensitive comparison
              return travellerEmail &&
                sentEmails.some(
                  (sentEmail: any) =>
                    sentEmail?.toLowerCase() === travellerEmail?.toLowerCase()
                )
                ? "Yes"
                : "No";
            },
          },
        },

        {
          field: "gstin_detail.gstin",
          headerName: "Customer GSTIN",
          width: 300,
          filter: "agTextColumnFilter",
          enableRowGroup: true,
          formatType: "CUSTOM_RENDER",
          ...handleCellRenderer(),
          formatProps: {
            valueGetterData: (data: any) => {
              return data?.gstin_detail?.[0]?.gstin
                ? data?.gstin_detail?.[0]?.gstin
                : data?.gstin_detail?.[0]?.state_short_name
                  ? "GSTIN is Inactive/ISD"
                  : "Entity Not Present in same State as hotel";
            },
            cellRenderer: (data: any, params: any) => {
              return params?.gstin_detail?.[0]?.gstin ? (
                <Tag color="blue">{params?.gstin_detail?.[0]?.gstin}</Tag>
              ) : (
                <Tag color="red">
                  {params?.gstin_detail?.[0]?.state_short_name
                    ? "GSTIN is Inactive/ISD"
                    : "Entity Not Present in same State as hotel"}
                </Tag>
              );
            },
          },
          // columnGroupShow: "close",
        },
        {
          field: "gstin_detail.company_name",
          headerName: "Customer Name",
          width: 300,
          enableRowGroup: true,
          columnGroupShow: "open",
          formatType: "CUSTOM_RENDER",
          ...handleCellRenderer(),
          valueGetter: (params: any) => {
            return params?.data?.gstin_detail?.[0]?.company_name || "";
          },
          filter: "agTextColumnFilter",
          formatProps: {
            cellRenderer: (data: any, params: any) => {
              return (
                <Tag color="blue">
                  {params?.gstin_detail?.[0]?.company_name}
                </Tag>
              );
            },
          },
        },

        {
          field: "gstin_detail.[0].address",
          headerName: "Customer Address",
          width: 200,
          filter: "agTextColumnFilter",
          columnGroupShow: "open",
          formatType: "CUSTOM_RENDER",
          ...handleCellRenderer(),
          formatProps: {
            cellRenderer: (data: any, params: any) =>
              params?.gstin_detail?.[0]?.address,
          },
        },

        {
          field: "gstin_detail.[0].address",
          headerName: "Customer State",
          width: 200,
          columnGroupShow: "open",
          formatType: "CUSTOM_RENDER",
          filter: "agTextColumnFilter",
          ...handleCellRenderer(),
          valueGetter: (params: any) => {
            return params?.data?.gstin_detail?.[0]?.state_short_name || "";
          },
          formatProps: {
            cellRenderer: (data: any, params: any) =>
              params?.gstin_detail?.[0]?.state_short_name,
          },
        },
      ],
    },
    {
      headerName: "Fare Details",
      children: [
        {
          field: "booking_data.segments.fare.estimatedTotalRate.amount",
          headerName: "Total Fare",
          width: 200,
          filter: "agNumberColumnFilter",
          columnGroupShow: "close",
          formatType: "AMOUNT",
          ...handleCellRenderer(),
          enableValue: true,
          // filter: "agNumberColumnFilter",
          enableRowGroup: true,
        },
        {
          field: "booking_data.segments.fare.estimatedTotalRate.amount",
          headerName: "Total GST Amount",
          width: 200,
          columnGroupShow: "close",
          enableValue: true,
          filter: "agNumberColumnFilter",
          formatType: "CUSTOM_RENDER",
          ...handleCellRenderer(),
          valueGetter: (params: any) =>
            getGSTCalculated(
              params.data?.booking_data?.segments?.fare?.estimatedTotalRate
                .amount || params.value
            ),
          formatProps: {
            cellRenderer: (data: any, params: any) => {
              return (
                <div style={{ textAlign: "right", width: "100%" }}>
                  <FormattedNumber
                    value={getGSTCalculated(data)}
                    style="currency"
                    currency="INR"
                  />
                </div>
              );
            },
          },
        },
        {
          field: "booking_data.segments.fare.rate.amount",
          headerName: "Booking Rate / Day",
          formatType: "AMOUNT",
          ...handleCellRenderer(),
          columnGroupShow: "open",
          filter: "agNumberColumnFilter",
        },
        {
          field: "booking_data.segments.fare.rate.amount",
          headerName: "Tax Rate",
          formatType: "CUSTOM_RENDER",
          filter: "agNumberColumnFilter",
          ...handleCellRenderer(),
          valueGetter: (params: any) => {
            let totalAmount =
              params.data?.booking_data?.segments?.fare?.estimatedTotalRate
                ?.amount;
            if (totalAmount < 1000) return 0;
            else if (totalAmount < 7500) return 12;
            else return 18;
          },
          formatProps: {
            cellRenderer: (data: any, params: any) => {
              let totalAmount =
                params?.booking_data?.segments?.fare?.estimatedTotalRate.amount;
              return totalAmount < 1000
                ? "NIL"
                : totalAmount < 7500
                  ? "12%"
                  : "18%";
            },
          },
          columnGroupShow: "open",
        },
        {
          field: "booking_data.segments.fare.rateCode",
          headerName: "Rate Code",
          columnGroupShow: "open",
          ...handleCellRenderer(),
          filter: "agTextColumnFilter",
        },
        {
          field: "booking_data.segments.fare.rateDescription",
          headerName: "Rate Code Description",
          filter: "agTextColumnFilter",
          width: 240,
          ...handleCellRenderer(),
          valueGetter: (params: any) => {
            return (
              params?.data?.booking_data?.segments?.fare?.rateDescription || ""
            );
          },
          columnGroupShow: "open",
        },
        {
          field: "booking_data.segments.fare.rateChangeDuringStay",
          headerName: "Rate Change During Stay",
          columnGroupShow: "open",
          ...handleCellRenderer(),
        },
        {
          field: "booking_data.segments.fare.rateChangeDuringStay",
          headerName: "Rate Change Description",
          columnGroupShow: "open",
          ...handleCellRenderer(),
          formatType: "CUSTOM_RENDER",

          formatProps: {
            cellRenderer: (data: any, params: any) => {
              return (
                <div
                  style={{
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {(
                    params?.booking_data?.segments?.fare?.rateChangeInfos || []
                  ).map((rateChange: any, key: any) => {
                    return (
                      <div
                        key={key}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <Typography
                          variant="xs"
                          style={{
                            color: colorPicker("neutral.800"),
                            marginRight: 6,
                          }}
                        >
                          {rateChange.beginDateTime ? "Date Change" : ""}
                        </Typography>
                        {rateChange.beginDateTime ? (
                          <>
                            <Tag>
                              {moment(rateChange.beginDateTime).format(
                                "DD-MM-YYYY"
                              )}
                            </Tag>
                            {" - "}{" "}
                            <Tag>
                              {moment(rateChange.endDateTime).format(
                                "DD-MM-YYYY"
                              )}
                            </Tag>
                          </>
                        ) : null}
                      </div>
                    );
                  })}
                </div>
              );
            },
          },
        },
        {
          field: "booking_data.segments.fare.commission.percent",
          headerName: "Comission",
          width: 300,
          enableRowGroup: true,
          columnGroupShow: "open",
          formatType: "CUSTOM_RENDER",
          ...handleCellRenderer(),
          // valueGetter: (params: any) =>
          //   params.data?.booking_data?.segments?.fare?.commission?.percent
          //     ? `${params.data?.booking_data?.segments?.fare?.commission?.percent}%`
          //     : "NA",
          formatProps: {
            cellRenderer: (data: any, params: any) => {
              return (
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {params?.booking_data?.segments?.fare?.commission?.percent
                    ? `${params?.booking_data?.segments?.fare?.commission?.percent}%`
                    : "NA"}
                </div>
              );
            },
          },
        },
      ],
    },

    {
      field: "gstin",
      headerName: "GSTIN Match Logic",
      width: 340,
      formatType: "CUSTOM_RENDER",
      ...handleCellRenderer(),
      exportIgnore: true,
      formatProps: {
        cellRenderer: (data: any, params: any) => {
          return (
            <div
              style={{ height: "100%", display: "flex", alignItems: "center" }}
            >
              <Tag>Hotel_Details.Hotel_State</Tag> ==
              <Tag>Customer_Details.State</Tag>
            </div>
          );
        },
      },
    },

    {
      headerName: "Email Event Logs",
      children: [
        {
          field: "booking_events",
          headerName: "Booking Email",
          width: 300,
          columnGroupShow: "open",
          formatType: "CUSTOM_RENDER",
          filter: "agSetColumnFilter",
          filterParams: {
            values: [
              "processed",
              "dropped",
              "deferred",
              "bounce",
              "delivered",
              "open",
              "click",
            ],
            valueFormatter: (params: any) =>
              Array.isArray(params.value)
                ? params.value.join(", ")
                : params.value,
            comparator: (a: any, b: any) => a.localeCompare(b),
          },
          ...handleCellRenderer(),
          formatProps: {
            cellRenderer: (data: any, params: any) => {
              return handleRenderEmailEvents(
                params?.email_events?.BOOKING || null
              );
            },
            valueGetterData: (params: any) => {
              return Array.isArray(
                Object.keys(params?.email_events?.BOOKING || {})
              )
                ? Object.keys(params?.email_events?.BOOKING || {})
                : [];
            },
          },
          valueGetter: (params: any) => {
            const bookingEvents = params.data?.email_events?.BOOKING || {};
            return Object.keys(bookingEvents).join(", ");
          },
        },

        {
          field: "checkin_events",
          headerName: "Checkin Email",
          width: 300,
          columnGroupShow: "open",
          formatType: "CUSTOM_RENDER",
          filter: "agSetColumnFilter",
          filterParams: {
            values: [
              "processed",
              "dropped",
              "deferred",
              "bounce",
              "delivered",
              "open",
              "click",
            ],
            valueFormatter: (params: any) =>
              Array.isArray(params.value)
                ? params.value.join(", ")
                : params.value,
            comparator: (a: any, b: any) => a.localeCompare(b),
          },
          ...handleCellRenderer(),
          formatProps: {
            cellRenderer: (data: any, params: any) => {
              return handleRenderEmailEvents(
                params?.email_events?.CHECKIN || null
              );
            },
            valueGetterData: (params: any) => {
              return Array.isArray(
                Object.keys(params?.email_events?.CHECKIN || {})
              )
                ? Object.keys(params?.email_events?.CHECKIN || {})
                : [];
            },
          },
          valueGetter: (params: any) => {
            const checkInEvents = params.data?.email_events?.CHECKIN || {};
            return Object.keys(checkInEvents);
          },
        },
        {
          field: "checkout_events",
          headerName: "Checkout Email",
          width: 300,
          columnGroupShow: "close",
          formatType: "CUSTOM_RENDER",
          filter: "agSetColumnFilter",
          filterParams: {
            values: [
              "processed",
              "dropped",
              "deferred",
              "bounce",
              "delivered",
              "open",
              "click",
            ],
            valueFormatter: (params: any) =>
              Array.isArray(params.value)
                ? params.value.join(", ")
                : params.value,
            comparator: (a: any, b: any) => a.localeCompare(b),
          },
          ...handleCellRenderer(),
          formatProps: {
            cellRenderer: (data: any, params: any) => {
              return handleRenderEmailEvents(params?.email_events?.COMPLETED);
            },
            valueGetterData: (params: any) => {
              return Array.isArray(
                Object.keys(params?.email_events?.COMPLETED || {})
              )
                ? Object.keys(params?.email_events?.COMPLETED || {})
                : [];
            },
          },
          valueGetter: (params: any) => {
            const checkOutEvents = params.data?.email_events?.COMPLETED || {};
            return Object.keys(checkOutEvents);
          },
        },
        {
          field: "checkin_date",
          headerName: "Monthly Report Email",
          width: 300,
          columnGroupShow: "open",
          formatType: "CUSTOM_RENDER",
          exportIgnore: true,
          ...handleCellRenderer(),
          cellRenderer: (params: any) => {
            return handleRenderEmailEvents(
              params.data?.email_events?.MONTHLY_REPORT
            );
          },
        },
        {
          field: "checkin_date",
          headerName: "Before GSTR Filling Email",
          width: 300,
          columnGroupShow: "open",
          formatType: "CUSTOM_RENDER",
          ...handleCellRenderer(),
          exportIgnore: true,

          formatProps: {
            cellRenderer: (data: any, params: any) => {
              return handleRenderEmailEvents(params?.email_events?.BEFORE_GSTR);
            },
          },
        },
        {
          field: "checkin_date",
          headerName: "After GSTR 2B Email",
          width: 300,
          columnGroupShow: "open",
          formatType: "CUSTOM_RENDER",
          ...handleCellRenderer(),
          exportIgnore: true,
          formatProps: {
            cellRenderer: (data: any, params: any) => {
              return handleRenderEmailEvents(params?.email_events?.AFTER_GSTR);
            },
          },
        },
      ],
    },


    // {
    //   field: "sendmail",
    //   headerName: "Invoice",
    //   enableRowGroup:true,
    //   // formatType:"CUSTOM_RENDER",
    //   ...handleCellRenderer(),
    //   valueGetter: (params: any) => {
    //     console.log("****************");
    //     console.log("Value Getter Params:", params);
    //     const links = params.data?.invoice_link || [];
    //     return links.length > 0 ? "Available" : "Pending";
    //   },
    //   cellRenderer: (params: any) => {


    //     console.log("Cell Renderer Params:", params);
    //     const links = params.data?.invoice_link || [];  
    //     return links.length > 0 ? (
    //       <div>
    //         {links.map((link: any, index: any) => (
    //           <Tag
    //             key={index}
    //             color="green"
    //             icon={<DownloadOutlined />}
    //             style={{ cursor: "pointer",
    //                marginRight: 4 }}
    //             onClick={()=>handleOpenInvoices(links)}
    //           >
    //             Download
    //           </Tag>
    //         ))}
    //       </div>
    //     ) : (
    //       <Tag
    //         color="yellow"
    //         icon={<EllipsisOutlined />}
    //         style={{ cursor: "pointer" }}
    //         onClick={()=>handleOpenUploadLink(params)}
    //       >
    //         Pending
    //       </Tag>
    //     );
    //   },
    // },

    {
      field: "sendmail",
      headerName: "Invoice",
      enableRowGroup: true,
      valueGetter: (params: any) => {
        const links = params.data?.invoice_link || [];
        return links.length > 0 ? "Available" : "Pending";
      },
      cellRenderer: (params: any) => {
        console.log(params.node.group,"params node group")
      
        // if (params.node.group) {
        //   console.log("params.node.allLeafChildren:", params.node.allLeafChildren);
        //   return (
        //     <button
        //       // onClick={() => handleDownloadGroupedInvoices(params.node.allLeafChildren)}
        //       style={{ cursor: "pointer", color: "blue" }}
        //     >
        //       Download All
        //     </button>
        //   );
        // }
      
        const links = params.data?.invoice_link || [];
        return links.length > 0 ? (
          <div>
            {links.map((link: any, index: any) => (
              <Tag
                key={index}
                color="green"
                icon={<DownloadOutlined />}
                style={{ cursor: "pointer", marginRight: 4 }}
                onClick={() => handleOpenInvoices(links)} // Use handleOpenInvoices here
              >
                Download
              </Tag>
            ))}
          </div>
        ) : (
          <Tag
            color="yellow"
            icon={<EllipsisOutlined />}
            style={{ cursor: "pointer" }}
            onClick={() => handleOpenUploadLink(params)}
          >
            Pending
          </Tag>
        );
      }
    },
      
    



    {
      headerName: "View invoice",
      field: "invoice_link",
      filter: "agTextColumnFilter",
      cellRenderer: ToolParamInvoker,
      cellRendererParams: (params: any) => {
        // console.log("Invoice Link Column - Params:", params.data?.invoice_link);
        return {
          label: "View Invoice",
          handleClick: openSidebarRenderer,
          data: params.data?.invoice_link,
        };
      },
    },




    {
      field: "sendmail",
      headerName: "Manual Action",
      formatType: "CUSTOM_RENDER",
      ...handleCellRenderer(),
      exportIgnore: true,
      formatProps: {
        cellRenderer: (data: any, params: any) => {
          let checkoutDay = moment().isAfter(
            moment(params?.booking_data?.tripDetails?.tripEndDateTime)
          );
          let checkinDay = moment().isAfter(
            moment(params?.booking_data?.tripDetails?.tripStartDateTime)
          );

          return params?.status === "COMPLETED" ? null : checkinDay ||
            checkoutDay ? (
            <Button
              size="small"
              type="primary"
              onClick={() => handleTriggerMail(params)}
            >
              {CUSTOM_MAIL_SEND_ACTIONS[params?.status]}
            </Button>
          ) : null;
        },
      },
    },

    {
      headerName: "Last Email Sent",
      field: "mailSentAt",
      valueFormatter: (params: any) => {
        const timestamp = params.value;
        if (timestamp) {
          const date = new Date(timestamp);
          return moment(date).format("DD-MM-YYYY HH:mm:ss");
        }
        return null;
      },
    },
  ];

  const [offset, setOffset] = useState(0);  // Initialize offset state
  const limit = 10; 



  // const fetchBookingData = async (localOffset = offset) 
  //   setLoading(true);
  //   console.log(localOffset, "offset");
  
  //   try {
  //     const response = await fetch(`${API_ENDPOINT_FETCH_HOTEL_BOOKING_NEW}?limit=${limit}&offset=${localOffset}`, {
  //       method: 'GET',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       }
  //     });
  
  //     const data = await response.json();
  //     console.log("response in fetchBookingData", data);
  
  //     if (response.ok) {
 
  //       setBookingList(prevList => [...prevList, ...data.data.data]);
  

  //       const currentSize = data.data.currentSize || 0;
  //       const newOffset = localOffset + currentSize; 
  
  //       const remainingRecords = data.data.remainingRecords || 0;  
  
  
  //       if (remainingRecords > 0) {
  //         fetchBookingData(newOffset); 
  //       }
  

  //       setOffset(newOffset);
  //     } else {
  //       console.error("Error fetching booking data:", data);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching booking data:", error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // const fetchBookingData = async () => {
  //   setLoading(true);
  //   console.log(offset, "offset");
  
  //   try {
  //     const response = await fetch(API_ENDPOINT_FETCH_HOTEL_BOOKING_NEW, {
  //       method: 'GET',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify({ limit, offset })  // Sending body with limit and offset
  //     });
  
  //     const data = await response.json();
  //     console.log("response in fetchBookingData", data);
  
  //     if (response.ok) {
  //       // Append the fetched data to the existing list
  //       setBookingList(prevList => [...prevList, ...data.data.data]);
  
  //       // Update offset and check if there are more records to fetch
  //       const currentSize = data.data.currentSize || 0;
  //       setOffset(prevOffset => prevOffset + currentSize);  // Increment the offset by the currentSize value
  
  //       const remainingRecords = data.data.remainingRecords || 0;  // Default to 0 if not present
  
  //       // If there are more records to fetch, recursively call the function
  //       if (remainingRecords > 0) {
  //         fetchBookingData();  // Recursive call until remainingRecords is zero
  //       }
  //     } else {
  //       console.error("Error fetching booking data:", data);
  //     }
  //   } catch (error) {
  //     console.error("Error fetching booking data:", error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const fetchBookingData = async () => {
    setLoading(true); 
    const limit = 10; 
    let offset = 0; 
  
    const fetchData = async (offsetValue: number) => {
      console.log(offset, "Current Offset Value");
  
      try {
        const response = await apiPost(API_ENDPOINT_FETCH_HOTEL_BOOKING_NEW, {
          limit,
          offset: offsetValue, 
        });
  
        console.log("Response in fetchBookingData", response);
  
        if (response.status) {
          const { data } = response;
          const currentSize=response.data.currentSize ;

          const remainingRecords = response.data.remainingRecords;
  

          console.log(`Current Batch Size: ${currentSize}`);
          console.log(`Remaining Records: ${remainingRecords}`);
  

          setBookingList((prevList) => [...prevList, ...data.data]);

          if (remainingRecords > 0) {

            offset += currentSize;

            await fetchData(offset);
          }
        }
      } catch (error) {
        console.error("Error fetching booking data:", error);
      }
    };
  
  
    await fetchData(offset);
  
    setLoading(false); 
  };
  
  


useEffect(() => {
  fetchBookingData();
}, []);






  
  
  

  const renderTemplate = (htmlContent: any, data: any) => {
    const templateCompiler = Handlebars.compile(htmlContent);
    const renderedContent = templateCompiler(data);
    console.log("compiled html", templateCompiler);

    return renderedContent;
  };

  const getTemplateDynamicData = (data: any) => {
    let tempData: any = {
      invoice_upload_link: `http://localhost:3000/hotel_booking/upload?ref=${"tessToken"}`,
      bcd_logo:
        "https://www.bcdgroup.com/wp-content/uploads/2019/07/BCD-Travel-Logo-300x109.png",
      novartis_logo:
        "https://static.wixstatic.com/media/7b0ae8_8f33b9fa9a7640078ede15059a3193d6~mv2.png/v1/fill/w_560,h_198,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/7b0ae8_8f33b9fa9a7640078ede15059a3193d6~mv2.png",
      subject: "⏰ GST Details for generating invoice",
      acknowledge_mail_url: `https://stage-apiplatform.finkraftai.com/api/webhook/sendgrid/email/ack/${"tessToken"}`,
      upload_invoice_url: `https://hotel.finkraftai.com/hotel_booking/upload?ref=${"tessToken"}`,
      //@ts-ignore
      booking_activity: hotelBookingActivityType[data?.status || "PENDING"],
      entity: {
        name: data?.gstin_detail[0].company_name,
        gstin: data?.gstin_detail[0].gstin,
        address: data?.gstin_detail[0].address,
      },
      hotel_details: {
        hotel_name: data?.booking_data.segments.title,
      },
      booking_details: {
        confirmation_number: data?.booking_data.segments.confirmationNumber,
      },
      checkin_date: moment(
        data?.booking_data.tripDetails.tripStartDateTime
      ).format("DD/MM/YYYY"),
      booking_date:
        moment(data?.booking_data.tripDetails.bookingDateTime).format(
          "DD/MM/YYYY"
        ) || "fsdfsd",
      checkout_date: moment(
        data?.booking_data.tripDetails.tripEndDateTime
      ).format("DD/MM/YYYY"),

      travelinfo: {
        travelers: [],
        contact_persons: "Ambuj",

        travelers_all_string: "",
        hotel: [
          {
            confirmationNumber: data?.booking_data?.segments.confirmationNumber,
            name: data?.booking_data?.segments.title,
            property_code: data?.booking_data?.segments.property.code,
            address: `${data?.booking_data?.segments.property.address.address1} ${data?.booking_data?.segments.property.address.city.name} ${data?.booking_data?.segments.property.address.region.code}  ${data?.booking_data?.segments.property.address.postalCode}`,
            checkin_date: moment(
              data?.booking_data.tripDetails.tripStartDateTime
            ).format("DD/MM/YYYY"),
            booking_date:
              moment(data?.booking_data.tripDetails.bookingDateTime).format(
                "DD/MM/YYYY"
              ) || "fsdfsd",
            checkout_date: moment(
              data?.booking_data.tripDetails.tripEndDateTime
            ).format("DD/MM/YYYY"),
          },
        ],
        checkin_date: moment(
          data?.booking_data.tripDetails.tripStartDateTime
        ).format("DD/MM/YYYY"),
        booking_date:
          moment(data?.booking_data.tripDetails.bookingDateTime).format(
            "DD/MM/YYYY"
          ) || "fsdfsd",
        checkout_date: moment(
          data?.booking_data.tripDetails.tripEndDateTime
        ).format("DD/MM/YYYY"),
      },
    };

    data?.booking_data.travelers.forEach((traveler: any, key: any) => {
      tempData.travelinfo.travelers.push({
        name: `${traveler.name.middleName} ${traveler.name.firstName} ${traveler.name.lastName}`,
        id: key + 1,
      });
    });

    console.log("template data:", data, tempData);

    return tempData;
  };
  const loadImages = (container: HTMLElement) => {
    const images = container.querySelectorAll("img");
    const promises = Array.from(images).map((img) => {
      return new Promise<void>((resolve, reject) => {
        if (img.complete) {
          resolve();
        } else {
          img.onload = () => resolve();
          img.onerror = () => reject(new Error("Image failed to load"));
        }
      });
    });

    return Promise.all(promises);
  };

  const downloadTemplateAsImage = async (htmlContent: string, data: any) => {
    const templateCompiler = Handlebars.compile(htmlContent);
    const renderedContent = templateCompiler(data);

    // Create a temporary container to hold the rendered content
    const container = document.createElement("div");
    container.style.position = "absolute";
    container.style.top = "-9999px"; // Hide the container off-screen
    container.innerHTML = renderedContent;
    document.body.appendChild(container);

    // Load all images before capturing
    await loadImages(container);

    // Capture the content as an image
    html2canvas(container, { useCORS: true }).then((canvas: any) => {
      document.body.removeChild(container);

      // Convert the canvas to a Blob and save it
      canvas.toBlob((blob: any) => {
        if (blob) {
          saveAs(blob, `${templateViewMap[viewTemplate || 0]?.title}.png`);
        }
      });
    });
  };

  const contentRef: any = useRef();

  const handleDownloadTemplate = () => {
    const element: any = contentRef.current;
    html2canvas(element, {
      scrollX: 0, // Handle horizontal scrolling if needed
      scrollY: 0, // Handle vertical scrolling
      useCORS: true, // Enable if you have cross-origin images
    }).then((canvas: any) => {
      const link = document.createElement("a");
      link.download = "full-content.png";
      link.href = canvas.toDataURL("image/png");
      link.click();
    });
  };

  const renderModalContent = () => {
    switch (showModal) {
      case "ADD_HOTEL":
        return (
          <HotelDataUpdate
            bookingData={selectedBooking}
            onSuccess={() => {
              setShowModal("");
              // fetchBookingData();
            }}
            type="HOTEL"
          />
        );
      case "ADD_CC":
        return (
          <HotelDataUpdate
            bookingData={selectedBooking}
            onSuccess={() => {
              setShowModal("");
              // fetchBookingData();
            }}
            type="CC"
          />
        );
      case "TEMPLATE_VIEW":
        return (
          <div>
            <Tag
              style={{ marginBottom: 12 }}
              color="green"
              icon={<DownloadOutlined />}
              onClick={handleDownloadTemplate}
            >
              {templateViewMap[viewTemplate || 0]?.title}
            </Tag>
            <div
              style={{ height: "70vh", overflowY: "scroll" }}
              ref={contentRef}
              dangerouslySetInnerHTML={{
                __html: renderTemplate(
                  templateViewMap[viewTemplate || 0]?.data || "",
                  getTemplateDynamicData(templateRowData)
                ),
              }}
            ></div>
            <Button
              type="primary"
              style={{ marginTop: 16 }}
              onClick={() =>
                downloadTemplateAsImage(
                  templateViewMap[viewTemplate || 0]?.data || "",
                  getTemplateDynamicData(templateRowData)
                )
              }
            >
              Download Template
            </Button>
          </div>
        );
    }
  };
  const gridRef = useRef<any>(null);

  const onGridReady = (params: any) => {
    gridRef.current = params.api; // Set the API to gridRef

    console.log("Grid is ready, API set:", gridRef.current);
  };


  const handleExportTableData = () => {
    if (gridRef.current && gridRef.current.exportDataToExcel) {
      // Call the child component's function to export data
      gridRef.current.exportDataToExcel();
    }
  };

  const handleApplyFilterOnActivity = (activityData: any) => {
    if (gridRef.current && gridRef.current.exportDataToExcel) {
      // Call the child component's function
      if (activityData === null) {
        gridRef.current.setFilterModel({});
        setFilterApplied(false);
        return;
      }
      gridRef.current.setFilterModel({
        "booking_data.identification.recordLocator": {
          type: "contains",
          filter: activityData?.log?.booking_record_locator,
        },
      });
      setFilterApplied(true);
    }
  };

  const onFilterChangedHandler = (query: string) => {
    console.log("Query Input:", query);
    const isDate = moment(query,"DD-MM-YYYY", true).isValid();
    console.log(isDate, "date inside on filter changed handler function")

    if (gridRef.current) {
      if (isDate) {
        gridRef.current.onDateFilterChanged(query);
      } else {
        gridRef.current.onFilterTextBoxChanged(query);
      }
    }
  };






  return (
    <div className="HotelBookingView">
      <div className="HeaderContainer">
        <PageHeader
          title="Hotel Followup Reports"
          noAuth
          rightActions={
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ width: 200 }}>
                <SearchInput
                  placeholder="Search by Record Locator"
                  onSearch={(query: string) => onFilterChangedHandler(query)}
                />
              </div>

              <Button
                type="primary"
                size="small"
                icon={<ExportOutlined />}
                onClick={handleExportTableData}
                style={{ marginLeft: 12 }}
              >
                Export Report
              </Button>
            </div>
          }
        />
      </div>
      <div className="TableContainer">
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <div
              style={{
                height: "100%",
                width: "100%",
                flex: 1,
              }}
            >
              
            </div>

            <Button onClick={showDrawer} className="activityButton">
              Show activity
            </Button>

            <Drawer onClose={onClose} open={open}>
              <EmailActivityList
                applyFilter={handleApplyFilterOnActivity}
                clearFilters={() => handleApplyFilterOnActivity(null)}
              />
            </Drawer>
          </>
        )}
      </div>

      <Modal
        title={modalTitleMap[showModal] || "Modal"}
        open={showModal !== ""}
        onCancel={() => setShowModal("")}
        footer={false}
        width={showModal === "TEMPLATE_VIEW" ? 800 : 500}
      >
        {renderModalContent()}
      </Modal>

      <Modal
        title="Enter Hotel Invoice Number"
        visible={isInvoiceModalVisible}
        onOk={handleSave}
        onCancel={handleCancel}
        okText="Save"
        cancelText="Cancel"
      >
        <Input
          placeholder="Enter invoice number"
          value={invoiceNumber}
          onChange={(e) => setInvoiceNumber(e.target.value)}
        />
      </Modal>




      {contextHolder}
    </div>
  );
}
