import Typography from "app/shared/Typography";
import "./JoinWorkspace.scss";
import { colorPicker } from "app/utils/color.helper";
import OTP from "antd/es/input/OTP";
import { Button, Input, message } from "antd";
import poweredFinkraft from "static/images/poweredFinkraft.png";
import { useEffect, useState } from "react";
import OnboardingHeader from "../OnboardingHeader";
import { apiGet, apiPost } from "app/services/apiServices";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  API_ENDPOOINT_ONBOARDING_INFO,
  API_ENDPOOINT_ONBOARDING_INITIATE,
  API_ENDPOOINT_VERIFY_JOIN_INVITE_CODE,
} from "../../onboardingv2.constants";
import { ArrowLeftOutlined } from "@ant-design/icons";
import Loader from "app/shared/Loader";
import { API_ENDPOINT_AUTH_SIGNIN_VERIFY_OTP } from "app/scenes/Auth/auth.constants";
import { emit } from "process";
import GirlRunning from "static/images/girlrunning.png";
import { PlusOutlined } from "@ant-design/icons";
import { useRecoilState } from "recoil";
import { onboardInfo, userInfo } from "app/config/States/users";
export default function LandingDataPage(props: any) {
  const [activeScreen, setActiveScreen] = useState(0);
  const [emailOTP, setEmailTOP] = useState("");
  const [tokenInfo, setTokenInfo] = useState<any>(null);
  const [isLoading, setLoading] = useState(false);
  const [validating, setValidating] = useState(false);
  const { inviteCode } = useParams();
  const [newEmail, setNewEmail] = useState("");
  const [messageApi, contextHolder] = message.useMessage();
  const [userOnboardInfo, setOnboardInfo] = useRecoilState<any>(onboardInfo);

  const location = useLocation();
  const { state } = location || {};

  const navigate = useNavigate();
  useEffect(() => {
    getOnboardingInfo();
  }, []);
  const getOnboardingInfo = async () => {
    setValidating(true);
    let workspace = localStorage.getItem("currentWorkspace");
    const response = await apiGet(
      API_ENDPOOINT_ONBOARDING_INFO + "?workspace=" + workspace
    );
    if (response.status) {
      console.log("response", response);
      setOnboardInfo({
        currentWorkspace: response?.data?.workspace?.id,
        currentWorkspaceName: response?.data?.workspace?.name,
      });
    } else {
      messageApi.error({
        type: "error",
        content: "Could not verify token looks expired",
      });
    }
    setValidating(false);
  };

  console.log("userDetails", userOnboardInfo);
  return (
    <div className="JoinWorkspace">
      {props.noHeader ? null : (
        <div className="HeaderContainer">
          <OnboardingHeader title={userOnboardInfo?.currentWorkspaceName} />
        </div>
      )}

      <div className="wrapperContainer">
        <img
          src="https://upload.wikimedia.org/wikipedia/commons/6/61/Makemytrip_logo.svg"
          alt="headerLogo"
          className="mmtLogo"
        />
        <Typography
          variant="xs"
          style={{ marginTop: 28, color: colorPicker("neutral.800") }}
        >
          The deadline for claiming GST input credit for the 2023-2024 is fast
          approaching.
        </Typography>

        <img
          src={GirlRunning}
          alt="headerLogo"
          style={{ width: 400, marginTop: 34, marginBottom: 34 }}
        />

        <Typography variant="h6" weight={600}>
          Claiming for Travel GST is never been Easy
        </Typography>

        <Typography
          style={{ marginTop: 12, color: colorPicker("neutral.700") }}
          variant="xs"
        >
          MMT.finkraft.ai helps you in retrieving airline invoices and hotel
          e-invoices and reconcile with GST portal data
        </Typography>

        <Button
          style={{ width: "100%", marginTop: 24 }}
          onClick={() =>
            navigate("/tour", { state: { tokenInfo: state?.tokenInfo } })
          }
        >
          Demo
        </Button>
        <Button
          style={{ width: "100%", marginTop: 12 }}
          icon={<PlusOutlined />}
          type="primary"
          onClick={() =>
            navigate("/onboarding2/bookingpreview", {
              state: { tokenInfo: state?.tokenInfo },
            })
          }
        >
          Fetch booking data for MMT Details
        </Button>
      </div>

      {contextHolder}
    </div>
  );
}
