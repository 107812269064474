// Import statements for images
import ALLIANCE_AIR from "static/images/logoairline/AllianceAirlines.png";
import Aeroflot_Airlines from "static/images/logoairline/AeroflotAirlines.png";
import Air_Asia from "static/images/logoairline/Airasia.png";
import Air_Canada from "static/images/logoairline/AirCanada.png";
import Air_France from "static/images/logoairline/AirFrance.png";
import Air_India from "static/images/logoairline/AirIndia.png";
import Air_Mauritius from "static/images/logoairline/AirMauritius.png";
// import Air_New_Zealand from "static/images/logoairline/AirNewZealand.png";
// import Akasa_Air from "static/images/logoairline/AkasaAir.png";
import All_Nippon_Airways from "static/images/logoairline/AllNipponAirways.png";
import American_Airlines from "static/images/logoairline/AmericanAirlines.png";
import Asiana_Airlines from "static/images/logoairline/AsianaAirlines.png";
import British_Airways from "static/images/logoairline/BritishAirways.png";
import Cathay_Pacific from "static/images/logoairline/CathayPacific.png";
import Delta_Airlines from "static/images/logoairline/DeltaAirlines.png";
import Emirates from "static/images/logoairline/Emirates.png";
import Ethiopian_Airlines from "static/images/logoairline/EthiopianAirlines.png";
import Etihad_Airways from "static/images/logoairline/EtihadAirways.png";
import Finn_Air from "static/images/logoairline/FinnAir.png";
// import Go_Air from "static/images/logoairline/GoAir.png";
// import Gulf_Air from "static/images/logoairline/GulfAir.png";
import Indigo from "static/images/logoairline/Indigo.png";
import Japan_Airlines from "static/images/logoairline/JapanAirlines.png";
import Kenya_Airways from "static/images/logoairline/KenyaAirways.png";
import Klm_Royal_Dutch from "static/images/logoairline/KlmRoyalDutch.png";
import Korean_Air from "static/images/logoairline/KoreanAir.png";
import Kuwait_Airways from "static/images/logoairline/KuwaitAirways.png";
import Lufthansa_Airline from "static/images/logoairline/LufthansaAirline.png";
import Malaysia_Airlines from "static/images/logoairline/MalaysiaAirlines.png";
import Malindo_Airways from "static/images/logoairline/MalindoAirways.png";
import Oman_Air from "static/images/logoairline/OmanAir.png";
import Qantas_Airways from "static/images/logoairline/QantasAirways.png";
import Qatar_Airways from "static/images/logoairline/Quatarairlines.png";
import Saudi_Arabian_Airlines from "static/images/logoairline/Suadiairlines.png";
import Singapore_Airlines from "static/images/logoairline/SingaporeAirlines.png";
import Spicejet from "static/images/logoairline/Spicejet.png";
import Srilankan_Airlines from "static/images/logoairline/SrilankanAirlines.png";
// import Star_Air from "static/images/logoairline/StarAir.png";
import Swiss_Airlines from "static/images/logoairline/SwissAirlines.png";
import Thai_Airways from "static/images/logoairline/ThaiAirways.png";
import Turkish_Airlines from "static/images/logoairline/TurkishAirlines.png";
import United_Airlines from "static/images/logoairline/UnitedAirlines.png";
import Virgin_Atlantic from "static/images/logoairline/VirginAtlantic.png";
import Vistara from "static/images/logoairline/vistara.png";

// Object with airlines as keys and image variables as values
const airlineLogos: any = {
  "ALLIANCE AIR": ALLIANCE_AIR,
  "Aeroflot Airlines": Aeroflot_Airlines,
  "Air Asia": Air_Asia,
  "Air Canada": Air_Canada,
  "Air France": Air_France,
  "Air India": Air_India,
  "Air India Express": Air_India,
  "Akasa Air": "",
  "Air Mauritius": Air_Mauritius,
  // "Air New Zealand": Air_New_Zealand,
  // "Akasa Air": Akasa_Air,
  "All Nippon Airways": All_Nippon_Airways,
  "American Airlines": American_Airlines,
  "Asiana Airlines": Asiana_Airlines,
  "British Airways": British_Airways,
  "Cathay Pacific": Cathay_Pacific,
  "Delta Airlines": Delta_Airlines,
  Emirates: Emirates,
  "Ethiopian Airlines": Ethiopian_Airlines,
  "Etihad Airways": Etihad_Airways,
  "Finn Air": Finn_Air,
  // "Go Air": Go_Air,
  // "Gulf Air": Gulf_Air,
  Indigo: Indigo,
  "Japan Airlines": Japan_Airlines,
  "Kenya Airways": Kenya_Airways,
  "Klm Royal Dutch": Klm_Royal_Dutch,
  "Korean Air": Korean_Air,
  "Kuwait Airways": Kuwait_Airways,
  "Lufthansa Airline": Lufthansa_Airline,
  "Malaysia Airlines": Malaysia_Airlines,
  "Malindo Airways": Malindo_Airways,
  "Oman Air": Oman_Air,
  "Qantas Airways": Qantas_Airways,
  "Qatar Airways": Qatar_Airways,
  "Saudi Arabian Airlines": Saudi_Arabian_Airlines,
  "Singapore Airlines": Singapore_Airlines,
  Spicejet: Spicejet,
  "Spice Jet": Spicejet,
  "Srilankan Airlines": Srilankan_Airlines,
  // "Star Air": Star_Air,
  "Swiss Airlines": Swiss_Airlines,
  "Thai Airways": Thai_Airways,
  "Turkish Airlines": Turkish_Airlines,
  "United Airlines": United_Airlines,
  "Virgin Atlantic": Virgin_Atlantic,
  Vistara: Vistara,
};

export default airlineLogos;
