import Typography from "app/shared/Typography";
import "./BookingDataPreview.scss";
import { colorPicker } from "app/utils/color.helper";
import OTP from "antd/es/input/OTP";
import {
  Button,
  CountdownProps,
  Input,
  message,
  Modal,
  Segmented,
  Statistic,
  StatisticProps,
} from "antd";
import poweredFinkraft from "static/images/poweredFinkraft.png";
import { useEffect, useState } from "react";
import CountUp from "react-countup";
import OnboardAirlineReconView from "app/scenes/AppTour/components/OnboardAirlineReconView";
import { PlusOutlined, InfoCircleOutlined } from "@ant-design/icons";
import OnboardingHeader from "../OnboardingHeader";
import OnboardingHelpCenter from "../OnboardingHelpCenter";
import { apiGet } from "app/services/apiServices";
import {
  API_ENDPOOINT_ONBOARDING_BOOKING_PREVIEW,
  API_ENDPOOINT_ONBOARDING_INFO,
} from "../../onboardingv2.constants";
import Loader from "app/shared/Loader";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import RequestDataForm from "../RequestDataForm";
import { useRecoilState } from "recoil";
import { onboardInfo, userInfo } from "app/config/States/users";
import UnReconciledFlight from "app/scenes/AppTour/components/OnboardUnReconcilied/UnReconciledFlight";
import UnReconciledHotel from "app/scenes/AppTour/components/OnboardUnReconcilied/UnReconciledHotel";
import EmptyState from "app/shared/EmptyState";

const formatter = (value: any) => {
  return new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 0, // Ensure no decimal places are shown
    maximumFractionDigits: 0,
  }).format(value);
};

const { Countdown } = Statistic;

export default function BookingDataPreview(props: any) {
  const [activeScreen, setActiveScreen] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [bookingData, setBookingData] = useState<any>(null);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location || {};
  const deadline = new Date().getTime() + 1000 * 60 * 60 * 24 * 73; // 2 days from now
  const [userOnboardInfo, setOnboardInfo] = useRecoilState<any>(onboardInfo);
  const [dataType, setDataType] = useState("FLIGHT");
  const [messageApi, contextHolder] = message.useMessage();

  const onFinish: CountdownProps["onFinish"] = () => {
    console.log("finished!");
  };

  const onChange: CountdownProps["onChange"] = (val) => {
    if (typeof val === "number" && 4.95 * 1000 < val && val < 5 * 1000) {
      console.log("changed!");
    }
  };

  const getOnboardingInfo = async () => {
    setLoading(true);

    let workspace = localStorage.getItem("currentWorkspace");
    const response = await apiGet(
      API_ENDPOOINT_ONBOARDING_INFO + "?workspace=" + workspace
    );
    if (response.status) {
      setOnboardInfo({
        currentWorkspace: response?.data?.workspace?.id,
        currentWorkspaceName: response?.data?.workspace?.name,
        workspaceMeta: response?.data?.workspace?.meta,
      });
      fetchBookingGist();
    } else {
      navigate("/auth/login");
      messageApi.error({
        type: "error",
        content: "Could not verify token looks expired",
      });
    }
  };

  useEffect(() => {
    getOnboardingInfo();
  }, []);
  const fetchBookingGist = async (segmentType = "FLIGHT") => {
    const response = await apiGet(
      API_ENDPOOINT_ONBOARDING_BOOKING_PREVIEW + "?booking_type=" + segmentType
    );
    if (response.status) {
      if (response.data !== null) {
        setBookingData(response.data);
      } else {
        setShowModal(true);
      }
    } else {
    }
    setLoading(false);
  };

  return isLoading ? (
    <Loader />
  ) : (
    <div className="BookingDataPreview">
      <div className="HeaderContainer">
        <OnboardingHeader
          title={userOnboardInfo?.currentWorkspaceName || "Test Workspace"}
        />
      </div>
      <div style={{ paddingLeft: 20, marginTop: 24 }}>
        <Segmented<string>
          options={["FLIGHT", "HOTEL"]}
          value={dataType}
          onChange={(value) => {
            setDataType(value); // string
            fetchBookingGist(value);
          }}
        />
      </div>
      <div className="WrapperContainer">
        <div className="RowContainer">
          <div className="Column" style={{ width: "32%" }}>
            <Typography style={{ color: colorPicker("neutral.800") }}>
              You have{" "}
              <b style={{ color: "#eb4d4b" }}>
                {bookingData?.totalmissingurl || 0}
              </b>{" "}
              invoices missing to claim GST amount
            </Typography>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Statistic
                value={bookingData?.totalgstamount || 0}
                valueStyle={{
                  color: "#eb4d4b",
                  fontSize: "28px", // Change the font size here
                  fontWeight: 600,
                }}
                precision={1}
                formatter={(value: any) => (
                  <CountUp end={value} duration={2} formattingFn={formatter} />
                )}
              />
            </div>
          </div>
          <div className="Column" style={{ width: "10%" }}>
            <Typography
              style={{ color: colorPicker("neutral.800") }}
              variant="caption"
            >
              Booking Data
            </Typography>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Statistic
                value={bookingData?.totalbooking || 0}
                valueStyle={{
                  color: colorPicker("primary.700"),
                  fontSize: "28px", // Change the font size here
                  fontWeight: 600,
                }}
                formatter={(value: any) => (
                  <CountUp end={value as number} separator="," />
                )}
              />
            </div>
          </div>
          <div className="Column" style={{ width: "10%" }}>
            <Typography
              style={{ color: colorPicker("neutral.800") }}
              variant="caption"
            >
              Total Invoices
            </Typography>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Statistic
                value={bookingData?.totalinvoiceurl || 0}
                valueStyle={{
                  color: colorPicker("primary.700"),
                  fontSize: "28px", // Change the font size here
                  fontWeight: 600,
                }}
                formatter={(value: any) => (
                  <CountUp end={value as number} separator="," />
                )}
              />
            </div>
          </div>
          <div className="Column" style={{ width: "12%" }}>
            <Typography
              style={{ color: colorPicker("neutral.800") }}
              variant="caption"
            >
              Missing Invoices
            </Typography>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Statistic
                value={bookingData?.totalmissingurl || 0}
                valueStyle={{
                  color: colorPicker("primary.700"),
                  fontSize: "28px", // Change the font size here
                  fontWeight: 600,
                }}
                formatter={(value: any) => (
                  <CountUp end={value as number} separator="," />
                )}
              />
            </div>
          </div>
          <div className="Column" style={{ width: "31%" }}>
            <Typography
              variant="caption"
              style={{ color: colorPicker("neutral.700") }}
            >
              Deadline for claiming GST of 2023-2024 is approaching fast!
            </Typography>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Countdown
                value={deadline}
                onFinish={onFinish}
                format="D [days] HH [hours] mm [min] ss [sec]"
                valueStyle={{ color: "#eb4d4b", fontSize: 20, marginTop: 4 }}
              />
            </div>
          </div>
        </div>
        <div className="RowContainer" style={{ flex: 1, marginTop: 14 }}>
          {!bookingData?.totalbooking || bookingData?.totalbooking === 0 ? (
            <EmptyState
              title={"No Booking Data"}
              description={
                <Typography
                  variant="xs"
                  style={{ color: colorPicker("neutral.700") }}
                >
                  Please raise a request by clicking here{" "}
                  <b
                    style={{
                      color: colorPicker("primary.700"),
                      cursor: "pointer",
                    }}
                    onClick={() => setShowModal(true)}
                  >
                    Raise Request
                  </b>
                </Typography>
              }
            />
          ) : (
            <div className="Column" style={{ width: "100%", height: "100%" }}>
              {/* <OnboardAirlineReconView noFloat /> */}
              {dataType === "Flight" ? (
                <UnReconciledFlight singleWorkspace />
              ) : (
                <UnReconciledHotel singleWorkspace />
              )}
            </div>
          )}
        </div>
        <div
          className="RowContainer"
          style={{ display: "flex", marginTop: 12 }}
        >
          <div>
            <img
              style={{ widows: 172, height: 24 }}
              src={poweredFinkraft}
              alt="poweredLogo"
            />
          </div>
          <div
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
              justifyContent: "flex-end",
              marginLeft: 44,
            }}
          >
            <div style={{ marginBottom: 12 }}>
              <OnboardingHelpCenter />
            </div>
            <div>
              <Button
                icon={<PlusOutlined />}
                style={{ marginRight: 24 }}
                onClick={() =>
                  navigate("/onboarding2/invite_user", {
                    state: { tokenInfo: state?.tokenInfo },
                  })
                }
              >
                Invite User
              </Button>
              <Button
                icon={<PlusOutlined />}
                type="primary"
                onClick={() =>
                  navigate("/onboarding2/creds", {
                    state: { tokenInfo: state?.tokenInfo },
                  })
                }
              >
                Add GST No
              </Button>
            </div>
          </div>
        </div>
        {contextHolder}
      </div>

      <Modal
        title={"Request for data"}
        width={800}
        open={showModal}
        onCancel={() => setShowModal(false)}
        footer={false}
      >
        <RequestDataForm workspaceInfo={userOnboardInfo} />
      </Modal>
    </div>
  );
}
