export const SINGLE_MAIL_DATA = {
  invoice_upload_link: `http://localhost:3000/hotel_booking/upload?ref=${"tessToken"}`,
  bcd_logo:
    "https://www.bcdgroup.com/wp-content/uploads/2019/07/BCD-Travel-Logo-300x109.png",
  novartis_logo:
    "https://static.wixstatic.com/media/7b0ae8_8f33b9fa9a7640078ede15059a3193d6~mv2.png/v1/fill/w_560,h_198,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/7b0ae8_8f33b9fa9a7640078ede15059a3193d6~mv2.png",
  subject: "⏰ GST Details for generating invoice",
  acknowledge_mail_url: `https://stage-apiplatform.finkraftai.com/api/webhook/sendgrid/email/ack/${"tessToken"}`,
  upload_invoice_url: `https://hotel.finkraftai.com/hotel_booking/upload?ref=${"tessToken"}`,
  booking_activity: "🎉 Congratulation, you have booking for stay",
  entity: {
    name: "Novartis India Limited",
    gstin: "33AAACH2914F1Z5",
    address:
      "Novartis India Limited, Part of Ground Floor for Novartis India Limited, Shrimak Logistics 61, Alapakkam Main Road, Maduravoyal, Chennai, Tamil Nadu, 600095",
  },
  hotel_details: {
    hotel_name: "Novotel Chennai Chamiers Road",
  },
  booking_details: {
    confirmation_number: "9636YG7516",
  },
  travelinfo: {
    travelers: [],
    contact_persons: "Ambuj",

    travelers_all_string: "",
    hotel: [],
    checkin_date: "01/07/2024",
    booking_date: "08/07/2024	",
    checkout_date: "12/07/2024",
  },
};

export const hotelBookingActivityType = {
  PENDING: " 🎉 Congratulation, you have booking for stay ",
  BOOKING: " Hey!, Your guest has arrived at your hotel  ",
  CHECKIN: " Hurry Up!!, Your guest is about to check-out in next 5 hour ",
  CHECKOUT_1: " Hurry Up!!, Your guest is about to check-out in next 2 hours ",
  CHECKOUT_2: "You are late!!, Your guest has checked out already ",
};
