import { Button, ConfigProvider, Table } from "antd";
import Typography from "app/shared/Typography";
import Uploader from "app/shared/Uploader";
import { colorPicker } from "app/utils/color.helper";
import { useEffect, useState } from "react";
import { PlusOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { AgTableClient } from "app/shared/AgTable";
import AgTableEditButton from "app/shared/AgTable/AgTableClient/HelperTools/EditComponent";
import AgTableEditDeleteButton from "app/shared/AgTable/AgTableClient/HelperTools/EditDeleteComponent";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import * as csv from "csvtojson";

export default function AddGstinWidget(props: any) {
  const [isSubmit, setIsSubmit] = useState(false);
  const navigate = useNavigate();
  const [gstins, setGstins] = useState<any[]>([]);

  useEffect(() => {
    if (props.gstins) setGstins(props.gstins);
  }, [props.gstins]);
  const handleAddTableRow = () => {
    setGstins([...gstins, { username: "", password: "", id: uuidv4() }]);
  };

  const handleRowEdit = (rowData: any) => {
    const index = gstins.findIndex((obj) => obj.id === rowData.id);
    if (index !== -1) {
      gstins[index] = {
        ...gstins[index],
        username: rowData.username,
        password: rowData.password,
      };
    }
  };

  const handleAddGSTIN = () => {
    props.handleAddGST && props.handleAddGST(gstins);
  };

  const downloadSample = async () => {
    let csvString = `gstin,username,password
                    09AABCG3241G1ZL,testuser1,user_password
                    09AABCG3141G1ZL,testuser2,user_password
                    09AABCG8111G1ZL,testuser3,user_password`;
    try {
      // Convert the CSV string to a Blob
      const csvBlob = new Blob([csvString], { type: "text/csv" });

      // Convert the Blob object to a data URL
      const csvDataUrl = URL.createObjectURL(csvBlob);

      // Create a temporary anchor element
      const link = document.createElement("a");
      link.href = csvDataUrl;
      link.setAttribute("download", "sample_credentials.csv");

      // Trigger the download by programmatically clicking the anchor element
      document.body.appendChild(link);
      link.click();

      // Clean up
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading CSV file:", error);
    }
  };

  const handleUpload = async (file: any) => {
    if (!file.originFileObj) {
      const reader = new FileReader();

      reader.onload = async (e: any) => {
        const text = e.target.result;
        //@ts-ignore
        const jsonArray = await csv().fromString(text);
        console.log("jsonArray", jsonArray);
        // processFetchGSTIN(jsonArray);
        // setJSONData(jsonArray);
      };

      reader.readAsText(file);
    }
  };

  return (
    <div className="AddGstinWidget" style={{ height: 300 }}>
      {props.type === "BULK_GSTIN" ? (
        <div style={{ marginTop: 24, height: "85%" }}>
          <Uploader onSelect={handleUpload} />
          <Button
            size="small"
            style={{ marginTop: 12 }}
            onClick={downloadSample}
          >
            Download Sample
          </Button>
        </div>
      ) : (
        <div className="TableContainer" style={{ marginTop: 24, height: 180 }}>
          <Typography
            variant="xs"
            style={{ marginBottom: 4, color: colorPicker("neutral.700") }}
          >
            Total Gstin {gstins.length}
          </Typography>
          <AgTableClient
            // @ts-ignore
            rowData={gstins}
            hideToolbar
            autoResize
            onRowSaved={handleRowEdit}
            columnDefs={[
              {
                headerName: "Username",
                field: "username",
                editable: true,
              },
              {
                headerName: "Password",
                field: "password",
                editable: true,
              },
              {
                headerName: "",
                field: "action",
                enableRowGroup: true,
                editRow: true,
                focusKey: "username",
                cellRenderer: AgTableEditButton,
                cellRendererParams: {
                  onClick: (params: any) => console.log(params), // Define your edit logic here
                },

                // cellRenderer: (params: any) => (
                //   <div style={{ display: "flex", alignItems: "center" }}>
                //     <EditOutlined
                //       style={{
                //         marginRight: 8,
                //         color: colorPicker("neutral.700"),
                //         cursor: "pointer",
                //       }}
                //     />
                //     <DeleteOutlined
                //       style={{
                //         color: colorPicker("neutral.700"),
                //         cursor: "pointer",
                //       }}
                //     />
                //   </div>
                // ),
              },
            ]}
          />
          <ConfigProvider
            theme={{
              components: {
                Table: {
                  headerColor: colorPicker("neutral.700"),
                  headerBg: "#F8F9FB",
                },
              },
            }}
          >
            <Button
              style={{
                width: "100%",
                marginTop: 6,
              }}
              icon={<PlusOutlined />}
              onClick={handleAddTableRow}
            >
              Add More
            </Button>

            <Button
              type="primary"
              style={{ marginTop: 24, width: "100%" }}
              size="large"
              onClick={handleAddGSTIN}
            >
              Submit
            </Button>
          </ConfigProvider>
        </div>
      )}
    </div>
  );
}
