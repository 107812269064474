// import { useEffect, useState } from "react";
// import "../../AgTable.scss";
// import Loader from "app/shared/Loader";

// export default function PDFViewerTool(props: any) {
//   const { api } = props;
//   const [invoiceLinks, setInvoiceLinks] = useState<any[]>([]);

//   const [isLoading, setLoading] = useState(true);


//   const handleIframeLoad = () => {
//     setLoading(false);
//   };



  

//   const onRowClick = (params: any) => {
//     setLoading(true);
//     console.log("params inside PDF viewer tool", params)
//     let Irn = params.data?.[props?.dataKey];
//     console.log("Irn in pdf viewer tool", Irn)
//     console.log("------------------", typeof (Irn))

//     setInvoiceLinks(Irn);
//   };




//   useEffect(() => {
//     api.addEventListener("rowClicked", onRowClick);
//     return () => {
//       api.removeEventListener("rowClicked", onRowClick);
//     };
//   }, [api]);
//   console.log(invoiceLinks, "invoice links in pdf viewer")

//   return (
//     <div className="PDFViewerToolSidebar">
//       <div className="Container">
//         {invoiceLinks.map((invoice: any, key: any) => {
//           console.log("Invoice URL:", invoice);
//           return (
//             <div
//               className="PDFViewer"
//               style={{ width: invoiceLinks.length > 1 ? "50%" : "100%" }}
//               key={key}
//             >
//               {isLoading && <Loader />}
//               <embed
//                 width="100%"
//                 height="100%"
//                 src={invoice}
//                 type="application/pdf"
//                 id="pdfEmbed"
//                 onLoad={handleIframeLoad}
//                 style={{
//                   // display: isLoading ? "none" : "block",
//                   height: "100%",
//                   paddingBottom: 20,
//                 }}
//               ></embed>
//             </div>
//           );
//         })}
//       </div>
//     </div>
//   );
// }


import { useEffect, useState } from "react";
import "../../AgTable.scss";
import Loader from "app/shared/Loader";
import { API_ENDPOINT_STORAGE_S3_SIGNED_URL } from "app/scenes/HotelBookingView/hotel.booking.constants";
import { apiGet } from "app/services/apiServices";
export default function PDFViewerToolV2(props: any) {
  const [pdfData, setPdfData] = useState("");
  const [isLoading, setLoading] = useState(true);

  const handleIframeLoad = () => {
    setLoading(false);
  };

 

  const getS3SignedURL = async (urlData: any) => {
    console.log(urlData,"url data")
    console.log(props,"props")

    const response = await apiGet(
      API_ENDPOINT_STORAGE_S3_SIGNED_URL + "?url=" + urlData 
    );
    console.log(response,"response in pdf viewer")
    if (response.status) {
      setPdfData(response.data);
    }
  };

  useEffect(() => {
    if (props.pdfData && props.pdfData !== "") getS3SignedURL(props.pdfData);
    console.log("pdfv2 loaded", props.pdfData);
  }, [props]);

  console.log(pdfData,"pdfdata in PDFViewerTool")

  return (
    <div className="PDFViewerToolSidebar">
      <div className="Container">
        <div className="PDFViewer" style={{ width: "100%" }}>
        
          {isLoading && <Loader />}
          

          <embed
            width="100%"
            height="100%"
            src={pdfData}
            type="application/pdf"
            id="pdfEmbed"
            onLoad={handleIframeLoad}
            style={{
              // display: isLoading ? "none" : "block",
              height: "100%",
              paddingBottom: 20,
            }}
          ></embed>
        </div>
      </div>
    </div>
  );
}


