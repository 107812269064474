import {
  Avatar,
  Badge,
  Breadcrumb,
  Dropdown,
  Layout,
  MenuProps,
  Select,
} from "antd";

import "./PageHeader.scss";
import SearchInput from "../SearchInput";

import MenuList from "../PrimaryNav/MenuItems.json";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Typography from "../Typography";
import { colorPicker } from "app/utils/color.helper";
import { useRecoilState } from "recoil";
import { userInfo } from "app/config/States/users";
import { apiGet } from "app/services/apiServices";
import { API_ENDPOINT_GET_USER_INFO } from "app/scenes/Auth/auth.constants";
import SearchDropdown from "../WorkspaceSelector";
export default function PageHeader(props: any) {
  const [breadcrumItem, setBreadcrumbItem] = useState<any[]>([]);
  const location = useLocation();
  const [userDetails, setUserDetails] = useRecoilState<any>(userInfo);
  useEffect(() => {
    let menuItem = MenuList.find(
      (item: any) => item.route === location.pathname
    );
    if (menuItem) {
      setBreadcrumbItem([menuItem]);
    }
  }, [location.pathname]);

  const handleSelectWorkspce = (workspaceInfo: any) => {
    let newWorksapceData = { ...userDetails, currentWorkspace: workspaceInfo };
    setUserDetails(JSON.parse(JSON.stringify(newWorksapceData)));
    localStorage.setItem("currentWorkspace", workspaceInfo.id);
    fetchUserInfo(workspaceInfo);
  };

  const fetchUserInfo = async (workspaceInfo: any) => {
    const response = await apiGet(API_ENDPOINT_GET_USER_INFO);
    if (response.status) {
      setUserDetails({
        ...response.data,
        currentWorkspace: workspaceInfo,
        // clusterId: response.data.cluster_id,
      });
      // if (!response.data.hasOnboared) {
      //   navigate("/onboarding/welcome");
      // }
    }
    // setTimeout(() => {
    //   setLoading(false);
    // }, 1000);
  };

  const workspaceColorMap = [
    "#4555eb",
    "#209f85",
    "#fa694a",
    "#2c3e50",
    "#e74c3c",
  ];

  const PickWorkspaceColor = (word: string) => {
    const firstChar = word.charAt(0).toLowerCase();
    const alphabetIndex = firstChar.charCodeAt(0) - "a".charCodeAt(0);
    const colorIndex = Math.floor(
      alphabetIndex / (26 / workspaceColorMap.length)
    );
    return workspaceColorMap[colorIndex];
  };

  const { Option } = Select;

  return (
    <div className="PageHeader">
      <div className="HeaderLeft">
        <Typography
          weight={500}
          style={{ color: "var(--text-color-black)", fontSize: 14 }}
        >
          {props.title}
        </Typography>

        <div
          className="ItemContainer "
          style={{ marginRight: 24, width: 600, overflowX: "scroll" }}
        >
          {props.leftActions}
        </div>
      </div>
      <div className="HeaderRight">
        <div className="ItemContainer " style={{ marginRight: 24 }}>
          {props.rightActions}
        </div>
        <div className="ItemContainer">
          {/* <Dropdown
            menu={{ items: getDropdownItems() }}
            trigger={["click"]}
            overlayStyle={{
              width: 200,
              height: 300,
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <Avatar
                size={24}
                style={{
                  backgroundColor: workspaceColorMap[1],
                  color: workspaceColorMap[1],
                  cursor: "pointer",
                  borderRadius: 8,
                  marginRight: 6,
                }}
              >
                <Typography style={{ color: "white" }} variant="caption">
                  {`${userDetails?.currentWorkspace?.name?.[0]}`}
                </Typography>
              </Avatar>

              <Typography variant="caption">
                {userDetails?.currentWorkspace?.name}
              </Typography>

              <ExpandMoreRoundedIcon />
            </div>
          </Dropdown> */}
          {props.noAuth ? null : (
            <SearchDropdown
              workspaces={userDetails?.workspaces}
              handleSelectWorkspce={handleSelectWorkspce}
            />
          )}
          {/* <Select
            showSearch
            style={{ width: 260 }}
            placeholder="Select an option"
            optionFilterProp="children"
            variant="borderless"
            onChange={(data: any, fullObj: any) =>
              console.log("data for selected workspace", fullObj)
            }
            filterOption={(input, option: any) =>
              option.children.toLowerCase().includes(input.toLowerCase()) ||
              option.value.toLowerCase().includes(input.toLowerCase())
            }
            value={userDetails?.currentWorkspace?.id}
          >
            {(userDetails?.workspaces || []).map((worksapce: any, key: any) => {
              return (
                <Option key={key} value={worksapce.id}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                      overflow: "hidden",
                    }}
                  >
                    <div style={{ width: 30 }}>
                      <Avatar
                        size={24}
                        style={{
                          backgroundColor: PickWorkspaceColor(worksapce?.name),
                          color: workspaceColorMap[1],
                          cursor: "pointer",
                          borderRadius: 8,
                          marginRight: 6,
                        }}
                      >
                        <Typography
                          style={{ color: "white" }}
                          variant="caption"
                        >
                          {`${worksapce?.name?.[0]}`}
                        </Typography>
                      </Avatar>
                    </div>

                    <Typography variant="caption">{worksapce?.name}</Typography>
                  </div>
                </Option>
              );
            })}
          </Select> */}
        </div>
      </div>
    </div>
  );
}
