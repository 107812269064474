import PageHeader from "app/shared/PageHeader";
import "./HotelBookingView.scss";
import CustomReconTabs from "../Reconcilation/components/CustomReconTabs";
import { useEffect, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import { tabViewStates, userInfo } from "app/config/States/users";
import PendingIcon from "@mui/icons-material/Pending";
import DraftsOutlinedIcon from "@mui/icons-material/DraftsOutlined";
import MarkEmailReadOutlinedIcon from "@mui/icons-material/MarkEmailReadOutlined";
import AdsClickOutlinedIcon from "@mui/icons-material/AdsClickOutlined";
import PrivacyTipOutlinedIcon from "@mui/icons-material/PrivacyTipOutlined";
import UnsubscribeOutlinedIcon from "@mui/icons-material/UnsubscribeOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import WatchLaterOutlinedIcon from "@mui/icons-material/WatchLaterOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import DoneAllOutlinedIcon from "@mui/icons-material/DoneAllOutlined";
import MarkunreadMailboxOutlinedIcon from "@mui/icons-material/MarkunreadMailboxOutlined";
import ScheduleSendOutlinedIcon from "@mui/icons-material/ScheduleSendOutlined";
import { AgTableClient } from "app/shared/AgTable";
import { Button, ColorPicker, Modal, Tag, Tooltip, message } from "antd";
import {
  CloudUploadOutlined,
  PhoneFilled,
  MailOutlined,
  CheckCircleFilled,
  WarningFilled,
  PlusOutlined,
  InfoCircleOutlined,
  PercentageOutlined,
  MailFilled,
  EditFilled,
  EllipsisOutlined,
  DownloadOutlined,
  CloseCircleOutlined,
  ExportOutlined,
} from "@ant-design/icons";
import Typography from "app/shared/Typography";
import { EmailOutlined } from "@mui/icons-material";
import HotelDataUpdate from "./components/HotelData";
import moment from "moment";
import { apiGet, apiPost } from "app/services/apiServices";
import Handlebars from "handlebars";

import {
  API_ENDPOINT_FETCH_HOTEL_BOOKING,
  API_ENDPOINT_FETCH_TEMPLATE_INFO,
  API_ENDPOINT_TRIGGER_CUSTOM_MAIL,
  CUSTOM_MAIL_SEND_ACTIONS,
} from "./hotel.booking.constants";
import { colorPicker } from "app/utils/color.helper";
import { handleCellRenderer } from "app/shared/AgTable/AgUtility";
import { FormattedNumber } from "react-intl";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import { hotelBookingActivityType, SINGLE_MAIL_DATA } from "./templateData";
import UpgradeIcon from "static/icons/upgarde.svg";
const modalTitleMap: any = {
  TEMPLATE_VIEW: "Template Preview",
  ADD_HOTEL: "Update Hotel Info",
  ADD_CC: "Add CC Emails",
};

const colorOfEmailEvent: any = {
  processed: colorPicker("primary.700"),
  delivered: colorPicker("primary.700"),
  open: colorPicker("primary.700"),
  click: colorPicker("primary.700"),
  bounce: "#e74c3c",
  dropped: "#e74c3c",
  spammed: "#e74c3c",
  deffered: "#e74c3c",
};
const EVENT_ICON_MAP = {
  processed: (color: any) => (
    <CheckOutlinedIcon
      style={{
        fontSize: 18,
        color: color,
        cursor: "pointer",
      }}
    />
  ),
  delivered: (color: any) => (
    <DoneAllOutlinedIcon
      style={{
        fontSize: 18,
        color: color,
        cursor: "pointer",
      }}
    />
  ),
  open: (color: any) => (
    <DraftsOutlinedIcon
      style={{
        fontSize: 18,
        color: color,
        cursor: "pointer",
      }}
    />
  ),
  click: (color: any) => (
    <AdsClickOutlinedIcon
      style={{
        fontSize: 18,
        color: color,
        cursor: "pointer",
      }}
    />
  ),
  acknowledge: (color: any) => (
    <AssignmentTurnedInIcon
      style={{
        fontSize: 18,
        color: color,
        cursor: "pointer",
        marginRight: 28,
      }}
    />
  ),

  UNSUBSCRIBE: (color: any) => (
    <UnsubscribeOutlinedIcon
      style={{
        fontSize: 18,
        color: color,
        cursor: "pointer",
      }}
    />
  ),
  SPAMMED: (color: any) => (
    <PrivacyTipOutlinedIcon
      style={{
        fontSize: 18,
        color: color,
        cursor: "pointer",
      }}
    />
  ),
  bounce: (color: any) => (
    <CancelOutlinedIcon
      style={{
        fontSize: 18,
        color: color,
        cursor: "pointer",
      }}
    />
  ),

  dropped: (color: any) => (
    <MarkunreadMailboxOutlinedIcon
      style={{
        fontSize: 18,
        color: color,
        cursor: "pointer",
      }}
    />
  ),

  DEFFERED: (color: any) => (
    <ScheduleSendOutlinedIcon
      style={{
        fontSize: 18,
        color: color,
        cursor: "pointer",
      }}
    />
  ),
};

function formatDate(timestamp: any) {
  // Convert Unix timestamp to milliseconds
  const date = new Date(timestamp * 1000);

  // Use Moment.js to format the date
  const formattedDate = moment(date).format("DD-MM-YYYY, HH:MM A");

  return formattedDate;
}

const handleRenderEmailEvents = (eventData: any) => {
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        alignItems: "center",
      }}
    >
      {Object.entries(EVENT_ICON_MAP).map((emailEvent: any) => {
        return (
          <div
            style={{
              marginRight: 6,
              display: "flex",
              height: "100%",
              alignItems: "center",
            }}
          >
            <Tooltip
              placement="bottom"
              title={
                <div>
                  <Typography
                    style={{ color: "white", marginBottom: 12 }}
                    variant="xs"
                  >
                    {emailEvent[0]}
                  </Typography>
                  {(eventData?.[emailEvent[0]] || []).map((reader: any) => {
                    return (
                      <Tag key={reader.email} style={{ fontSize: 10 }}>
                        {reader.email} at {formatDate(reader.timestamp)}
                      </Tag>
                    );
                  })}
                </div>
              }
            >
              {emailEvent[1](
                eventData?.[emailEvent[0]]
                  ? colorOfEmailEvent[emailEvent[0]]
                  : "rgb(192 194 197)"
              )}
            </Tooltip>
          </div>
        );
      })}
    </div>
  );
};

const emailSentStatusMap = [
  "BOOKING",
  "CHECKIN",
  "CHECKOUT_1",
  "CHECKOUT_2",
  "COMPLETED",
  "MONTH_END",
  "BEFORE_GSTR",
  "AFTER_GSTR",
];

const emailSentStatusTitle: any = {
  BOOKING: "Email for Booking Done",
  CHECKIN: "Email for check-in",
  CHECKOUT_1: "Email for checkout reminder 5 hours before",
  CHECKOUT_2: "Email for checkout reminder 2 hours before",
  COMPLETED: "Email to notify about checkout done",
  MONTH_END: "Month End Report Email",
  BEFORE_GSTR: "Email consolidated before GSTR",
  AFTER_GSTR: "Email consolidated after GSTR",
};
export default function HotelBookingViewClient(props: any) {
  const [activeKey, setActiveKey] = useState(1);
  const [userDetails, _] = useRecoilState<any>(userInfo);
  const [showModal, setShowModal] = useState(false);
  const [bookingList, setBookingList] = useState([]);
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [messageApi, contextHolder] = message.useMessage();
  const [templateData, setTemplateData] = useState(null);
  const [templateDataBulk, setTemplateDataBulk] = useState(null);
  const [viewTemplate, setViewTemplate] = useState<any>();
  const [templateRowData, setTemplateRowData] = useState<any>();

  useEffect(() => {
    fetchTemplateInfo();
    fetchTemplateInfoBulk();
  }, []);
  const fetchTemplateInfo = async () => {
    const response = await apiGet(
      API_ENDPOINT_FETCH_TEMPLATE_INFO + "d-21ea8040c7c04a5595d073b2870fc7c9"
    );
    if (response) {
      setTemplateData(response.html_content);
    }
  };
  const fetchTemplateInfoBulk = async () => {
    const response = await apiGet(
      API_ENDPOINT_FETCH_TEMPLATE_INFO + "d-a4a07a17c35d4b96ac6e89c84ad3463c"
    );
    if (response) {
      setTemplateDataBulk(response.html_content);
    }
  };

  const templateViewMap: any = {
    0: {
      title: "Booking Data Template",
      data: templateData,
      json: SINGLE_MAIL_DATA,
    },
    1: {
      title: "Checkin Email Template",
      data: templateData,
      json: SINGLE_MAIL_DATA,
    },
    2: {
      title: "Checkout Data Template",
      data: templateData,
      json: SINGLE_MAIL_DATA,
    },
    3: {
      title: "Monthly Report Template",
      data: templateDataBulk,
      json: SINGLE_MAIL_DATA,
    },
    4: {
      title: "Before GSTR Template",
      data: templateDataBulk,
      json: SINGLE_MAIL_DATA,
    },
    5: {
      title: "After GSTR Template",
      data: templateDataBulk,
      json: SINGLE_MAIL_DATA,
    },
  };

  const handleOpenInvoices = (links: any) => {
    links.forEach((element: string) => {
      window.open(element, "_blank");
    });
  };
  const getValueOfMailStatus = (data: any) => {
    if (data?.email_events?.BOOKING?.dropped?.length > 0) {
      return "Dropped";
    }
    if (data?.email_events?.BOOKING?.bounce?.length > 0) {
      return "Bounce";
    }

    if (
      data?.hotel_details &&
      data?.hotel_details?.hotel_emails &&
      data?.hotel_details?.hotel_emails?.length > 0
    ) {
      return "Running";
    }
    console.log("bounce or dropped", data?.email_events);

    return "No Recipients";
  };

  const getGSTCalculated = (amount: any) => {
    let parsedAmount = parseFloat(amount);
    if (parsedAmount < 1000) {
      return 0;
    } else if (parsedAmount < 7500) {
      return (parsedAmount / 100) * 12;
    } else {
      return (parsedAmount / 100) * 18;
    }
  };
  const initialColDef = [
    {
      headerName: "Booking Details",
      children: [
        {
          field: "booking_data.tripDetails.tripEndDateTime",
          headerName: "Checkout Date & Time",
          width: 200,
          // columnGroupShow: "close",
          formatType: "CUSTOM_RENDER",
          ...handleCellRenderer(),
          formatProps: {
            cellRenderer: (data: any, params: any) => {
              return (
                <Tag>
                  {moment(
                    params?.booking_data?.tripDetails?.tripEndDateTime
                  ).format("DD-MM-YYYY")}
                </Tag>
              );
            },
          },
          filter: "agDateColumnFilter",
          filterParams: {
            comparator: (filterLocalDateAtMidnight: any, cellValue: any) => {
              const cellDate = new Date(cellValue);

              if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
                return 0;
              }

              if (cellDate < filterLocalDateAtMidnight) {
                return -1;
              }

              if (cellDate > filterLocalDateAtMidnight) {
                return 1;
              }
            },
          },
        },
        {
          field: "booking_data.segments.confirmationNumber",
          headerName: "Confirmation Number",
          // columnGroupShow: "open",
          formatType: "CUSTOM_RENDER",
          ...handleCellRenderer(),
          formatProps: {
            cellRenderer: (data: any, params: any) => {
              return (
                <Tag>{params?.booking_data?.segments?.confirmationNumber}</Tag>
              );
            },
          },
        },

        {
          field: "booking_data.identification.recordLocator",
          headerName: "Booking ID",
          // columnGroupShow: "open",
          formatType: "CUSTOM_RENDER",
          ...handleCellRenderer(),
          formatProps: {
            cellRenderer: (data: any, params: any) => {
              return (
                <Tag>{params?.booking_data?.identification?.recordLocator}</Tag>
              );
            },
          },
        },
        {
          field: "booking_data.tripDetails.bookingDateTime",
          headerName: "Booking Date",
          width: 200,
          formatType: "CUSTOM_RENDER",
          ...handleCellRenderer(),
          formatProps: {
            cellRenderer: (data: any, params: any) => {
              return moment(
                params.booking_data?.tripDetails?.bookingDateTime
              ).format("DD-MM-YYYY");
            },
          },
          // columnGroupShow: "open",
        },
        {
          field: "booking_data.tripDetails.tripStartDateTime",
          headerName: "Checkin Date & Time",
          width: 200,
          formatType: "CUSTOM_RENDER",
          ...handleCellRenderer(),
          formatProps: {
            cellRenderer: (data: any, params: any) => {
              return moment(
                params.booking_data?.tripDetails?.tripStartDateTime
              ).format("DD-MM-YYYY");
            },
          },
          // columnGroupShow: "open",
        },

        {
          field: "travellers",
          headerName: "Travellers",
          width: 300,
          // columnGroupShow: "open",
          formatType: "CUSTOM_RENDER",
          ...handleCellRenderer(),
          formatProps: {
            cellRenderer: (data: any, params: any) => {
              return (
                <div
                  style={{
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {params?.booking_data?.travelers?.map((traveler: any) => {
                    return (
                      <Tag>{`${traveler?.name.middleName} ${traveler?.name.firstName} ${traveler?.name.lastName}`}</Tag>
                    );
                  })}
                </div>
              );
            },
            valueGetterData: (data: any) => {
              let travellerNames = "";
              (data?.booking_data?.travelers || []).forEach(
                (travelerInfo: any) => {
                  travellerNames += `${travelerInfo?.name.middleName} ${travelerInfo?.name.firstName} ${travelerInfo?.name.lastName}`;
                }
              );
              return travellerNames;
            },
          },
        },
      ],
    },
    {
      headerName: "Hotel Details",
      children: [
        {
          field: "hotel_details.hotel_name",
          headerName: "Hotel Name",
          width: 300,
          enableRowGroup: true,
          // valueGetter: (params: any) => params.data?.hotel_details?.hotel_name,
          formatType: "CUSTOM_RENDER",
          ...handleCellRenderer(),
          formatProps: {
            cellRenderer: (data: any, params: any) => {
              return (
                <div style={{ display: "flex", alignItems: "center" }}>
                  {params?.hotel_details ? (
                    <>
                      <Typography variant="xs" style={{ marginRight: 12 }}>
                        {params?.hotel_details?.hotel_name}
                      </Typography>
                    </>
                  ) : (
                    <Tag
                      icon={<InfoCircleOutlined />}
                      color="red"
                      style={{ cursor: "pointer" }}
                    >
                      Add Hotel Info
                    </Tag>
                  )}
                </div>
              );
            },
          },
          columnGroupShow: "close",
        },

        {
          field: "booking_data.segments.property.address.address1",
          headerName: "Hotel Address",
          width: 200,
          // columnGroupShow: "open",
          // formatType: "CUSTOM_RENDER",
          ...handleCellRenderer(),
        },
        {
          field: "booking_data.segments.property.address.city.name",
          headerName: "Hotel City",
          width: 200,
          // columnGroupShow: "open",
          // formatType: "CUSTOM_RENDER",
          ...handleCellRenderer(),
        },
        {
          field: "booking_data.segments.property.address.region.code",
          headerName: "Hotel State",
          width: 200,
          // columnGroupShow: "open",
          filter: "agTextColumnFilter",
          ...handleCellRenderer(),
        },
      ],
    },

    {
      headerName: "Customer Details",
      children: [
        {
          field: "gstin_detail.company_name",
          headerName: "Mail Sent To Traveller",
          enableRowGroup: true,
          // columnGroupShow: "close",
          formatType: "CUSTOM_RENDER",
          ...handleCellRenderer(),
          formatProps: {
            cellRenderer: (data: any, params: any) => {
              let travellerEmail =
                params?.booking_data?.contacts?.emailAddresses?.[0]
                  ?.emailAddress;
              let sentEmails =
                params?.email_events?.CHECKOUT_2?.delivered?.map(
                  (emails: any) => emails.email
                ) || [];

              return (
                <Tag
                  icon={
                    sentEmails.includes(travellerEmail.toLowerCase()) ? (
                      <CheckCircleFilled />
                    ) : (
                      <CloseCircleOutlined />
                    )
                  }
                  color={
                    sentEmails.includes(travellerEmail.toLowerCase())
                      ? "green"
                      : "red"
                  }
                >
                  {sentEmails.includes(travellerEmail.toLowerCase())
                    ? "Yes"
                    : "No"}
                </Tag>
              );
            },
            valueGetterData: (data: any) => {
              let travellerEmail =
                data?.booking_data?.contacts?.emailAddresses?.[0]?.emailAddress;
              let sentEmails =
                data?.email_events?.CHECKOUT_2?.delivered?.map(
                  (emails: any) => emails.email
                ) || [];

              return sentEmails.includes(travellerEmail.toLowerCase())
                ? "Yes"
                : "No";
            },
          },
        },
        {
          field: "gstin_detail.gstin",
          headerName: "Customer GSTIN",
          width: 300,
          enableRowGroup: true,
          formatType: "CUSTOM_RENDER",
          ...handleCellRenderer(),
          formatProps: {
            valueGetterData: (data: any) => {
              return data?.gstin_detail?.[0]?.gstin
                ? data?.gstin_detail?.[0]?.gstin
                : data?.gstin_detail?.[0]?.state_short_name
                ? "GSTIN is Inactive/ISD"
                : "Entity Not Present in same State as hotel";
            },
            cellRenderer: (data: any, params: any) => {
              return params?.gstin_detail?.[0]?.gstin ? (
                <Tag color="blue">{params?.gstin_detail?.[0]?.gstin}</Tag>
              ) : (
                <Tag color="red">
                  {params?.gstin_detail?.[0]?.state_short_name
                    ? "GSTIN is Inactive/ISD"
                    : "Entity Not Present in same State as hotel"}
                </Tag>
              );
            },
          },
          // columnGroupShow: "close",
        },
        {
          field: "gstin_detail.company_name",
          headerName: "Customer Name",
          width: 300,
          enableRowGroup: true,
          // columnGroupShow: "open",
          formatType: "CUSTOM_RENDER",
          ...handleCellRenderer(),
          formatProps: {
            valueGetterData: (params: any) =>
              params?.gstin_detail?.[0]?.company_name,
            cellRenderer: (data: any, params: any) => {
              return (
                <Tag color="blue">
                  {params?.gstin_detail?.[0]?.company_name}
                </Tag>
              );
            },
          },
        },

        {
          field: "gstin_detail.[0].address",
          headerName: "Customer Address",
          width: 200,
          // columnGroupShow: "open",
          formatType: "CUSTOM_RENDER",
          ...handleCellRenderer(),
          formatProps: {
            cellRenderer: (data: any, params: any) =>
              params?.gstin_detail?.[0]?.address,
          },
        },

        {
          field: "gstin_detail?.[0]?.state_short_name",
          headerName: "Customer State",
          width: 200,
          // columnGroupShow: "open",
          formatType: "CUSTOM_RENDER",
          filter: "agTextColumnFilter",
          ...handleCellRenderer(),
          formatProps: {
            valueGetterData: (params: any) =>
              params?.gstin_detail?.[0]?.state_short_name,
            cellRenderer: (data: any, params: any) =>
              params?.gstin_detail?.[0]?.state_short_name,
          },
        },
      ],
    },

    {
      headerName: "Fare Details",
      children: [
        {
          field: "booking_data.segments.fare.estimatedTotalRate.amount",
          headerName: "Total Fare",
          width: 200,
          columnGroupShow: "close",
          formatType: "AMOUNT",
          ...handleCellRenderer(),
          enableValue: true,
          // filter: "agNumberColumnFilter",
          enableRowGroup: true,
        },
        {
          field: "booking_data.segments.fare.estimatedTotalRate.amount",
          headerName: "Total GST Amount",
          width: 200,
          columnGroupShow: "close",
          enableValue: true,
          filter: "agNumberColumnFilter",
          formatType: "CUSTOM_RENDER",
          ...handleCellRenderer(),
          valueGetter: (params: any) =>
            getGSTCalculated(
              params.data?.booking_data?.segments?.fare?.estimatedTotalRate
                .amount || params.value
            ),
          formatProps: {
            cellRenderer: (data: any, params: any) => {
              return (
                <div style={{ textAlign: "right", width: "100%" }}>
                  <FormattedNumber
                    value={getGSTCalculated(data)}
                    style="currency"
                    currency="INR"
                  />
                </div>
              );
            },
          },
        },
        {
          field: "booking_data.segments.fare.rate.amount",
          headerName: "Booking Rate / Day",
          formatType: "AMOUNT",
          ...handleCellRenderer(),
          columnGroupShow: "open",
        },
        {
          field: "booking_data.segments.fare.rate.amount",
          headerName: "Tax Rate",
          formatType: "CUSTOM_RENDER",
          ...handleCellRenderer(),
          formatProps: {
            cellRenderer: (data: any, params: any) => {
              let totalAmount =
                params?.booking_data?.segments?.fare?.estimatedTotalRate.amount;
              return totalAmount < 1000
                ? "NIL"
                : totalAmount < 7500
                ? "12%"
                : "18%";
            },
          },
          columnGroupShow: "open",
        },
        {
          field: "booking_data.segments.fare.rateCode",
          headerName: "Rate Code",
          columnGroupShow: "open",
          ...handleCellRenderer(),
        },
        {
          field: "booking_data.segments.fare.rateDescription",
          headerName: "Rate Code Description",
          width: 240,
          ...handleCellRenderer(),
          columnGroupShow: "open",
        },
        {
          field: "booking_data.segments.fare.rateChangeDuringStay",
          headerName: "Rate Change During Stay",
          columnGroupShow: "open",
          ...handleCellRenderer(),
        },
        {
          field: "booking_data.segments.fare.rateChangeDuringStay",
          headerName: "Rate Change Description",
          columnGroupShow: "open",
          ...handleCellRenderer(),
          formatType: "CUSTOM_RENDER",

          formatProps: {
            cellRenderer: (data: any, params: any) => {
              return (
                <div
                  style={{
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {(
                    params?.booking_data?.segments?.fare?.rateChangeInfos || []
                  ).map((rateChange: any, key: any) => {
                    return (
                      <div
                        key={key}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <Typography
                          variant="xs"
                          style={{
                            color: colorPicker("neutral.800"),
                            marginRight: 6,
                          }}
                        >
                          {rateChange.beginDateTime ? "Date Change" : ""}
                        </Typography>
                        {rateChange.beginDateTime ? (
                          <>
                            <Tag>
                              {moment(rateChange.beginDateTime).format(
                                "DD-MM-YYYY"
                              )}
                            </Tag>
                            {" - "}{" "}
                            <Tag>
                              {moment(rateChange.endDateTime).format(
                                "DD-MM-YYYY"
                              )}
                            </Tag>
                          </>
                        ) : null}
                      </div>
                    );
                  })}
                </div>
              );
            },
          },
        },
        {
          field: "booking_data.segments.fare.commission.percent",
          headerName: "Comission",
          width: 300,
          enableRowGroup: true,
          columnGroupShow: "open",
          formatType: "CUSTOM_RENDER",
          ...handleCellRenderer(),
          // valueGetter: (params: any) =>
          //   params.data?.booking_data?.segments?.fare?.commission?.percent
          //     ? `${params.data?.booking_data?.segments?.fare?.commission?.percent}%`
          //     : "NA",
          formatProps: {
            cellRenderer: (data: any, params: any) => {
              return (
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {params?.booking_data?.segments?.fare?.commission?.percent
                    ? `${params?.booking_data?.segments?.fare?.commission?.percent}%`
                    : "NA"}
                </div>
              );
            },
          },
        },
      ],
    },

    {
      field: "sendmail",
      headerName: "Invoice",
      formatType: "CUSTOM_RENDER",
      ...handleCellRenderer(),
      enableRowGroup: true,
      formatProps: {
        cellRenderer: (data: any, params: any) => {
          return (
            <Tag
              color="green"
              icon={<DownloadOutlined />}
              onClick={() => setShowModal(true)}
              style={{ cursor: "pointer" }}
            >
              Download
            </Tag>
          );
        },
        valueGetterData: (params: any) =>
          params?.invoice_link ? "Available" : "Pending",
      },
    },

    {
      headerName: "Email Event Logs",
      children: [
        {
          field: "booking_events",
          headerName: "Booking Email",
          width: 300,
          // columnGroupShow: "open",
          formatType: "CUSTOM_RENDER",
          filter: "agSetColumnFilter",
          filterParams: {
            values: [
              "processed",
              "dropped",
              "deferred",
              "bounce",
              "delivered",
              "open",
              "click",
            ],
            valueFormatter: (params: any) =>
              Array.isArray(params.value)
                ? params.value.join(", ")
                : params.value,
            comparator: (a: any, b: any) => a.localeCompare(b),
          },
          ...handleCellRenderer(),
          formatProps: {
            cellRenderer: (data: any, params: any) => {
              return handleRenderEmailEvents(
                params?.email_events?.BOOKING || null
              );
            },
            valueGetterData: (params: any) => {
              return Array.isArray(
                Object.keys(params?.email_events?.BOOKING || {})
              )
                ? Object.keys(params?.email_events?.BOOKING || {})
                : [];
            },
          },
        },

        {
          field: "checkin_events",
          headerName: "Checkin Email",
          width: 300,
          // columnGroupShow: "open",
          formatType: "CUSTOM_RENDER",
          filter: "agSetColumnFilter",
          filterParams: {
            values: [
              "processed",
              "dropped",
              "deferred",
              "bounce",
              "delivered",
              "open",
              "click",
            ],
            valueFormatter: (params: any) =>
              Array.isArray(params.value)
                ? params.value.join(", ")
                : params.value,
            comparator: (a: any, b: any) => a.localeCompare(b),
          },
          ...handleCellRenderer(),
          formatProps: {
            cellRenderer: (data: any, params: any) => {
              return handleRenderEmailEvents(
                params?.email_events?.CHECKIN || null
              );
            },
            valueGetterData: (params: any) => {
              return Array.isArray(
                Object.keys(params?.email_events?.CHECKIN || {})
              )
                ? Object.keys(params?.email_events?.CHECKIN || {})
                : [];
            },
          },
        },
        {
          field: "checkout_events",
          headerName: "Checkout Email",
          width: 300,
          // columnGroupShow: "close",
          formatType: "CUSTOM_RENDER",
          filter: "agSetColumnFilter",
          filterParams: {
            values: [
              "processed",
              "dropped",
              "deferred",
              "bounce",
              "delivered",
              "open",
              "click",
            ],
            valueFormatter: (params: any) =>
              Array.isArray(params.value)
                ? params.value.join(", ")
                : params.value,
            comparator: (a: any, b: any) => a.localeCompare(b),
          },
          ...handleCellRenderer(),
          formatProps: {
            cellRenderer: (data: any, params: any) => {
              return handleRenderEmailEvents(params?.email_events?.COMPLETED);
            },
            valueGetterData: (params: any) => {
              return Array.isArray(
                Object.keys(params?.email_events?.COMPLETED || {})
              )
                ? Object.keys(params?.email_events?.COMPLETED || {})
                : [];
            },
          },
        },
        {
          field: "checkin_date",
          headerName: "Monthly Report Email",
          width: 300,
          columnGroupShow: "open",
          formatType: "CUSTOM_RENDER",
          ...handleCellRenderer(),
          cellRenderer: (params: any) => {
            return handleRenderEmailEvents(
              params.data?.email_events?.MONTHLY_REPORT
            );
          },
        },
        {
          field: "checkin_date",
          headerName: "Before GSTR Filling Email",
          width: 300,
          columnGroupShow: "open",
          formatType: "CUSTOM_RENDER",
          ...handleCellRenderer(),

          formatProps: {
            cellRenderer: (data: any, params: any) => {
              return handleRenderEmailEvents(params?.email_events?.BEFORE_GSTR);
            },
          },
        },
        {
          field: "checkin_date",
          headerName: "After GSTR 2B Email",
          width: 300,
          columnGroupShow: "open",
          formatType: "CUSTOM_RENDER",
          ...handleCellRenderer(),
          formatProps: {
            cellRenderer: (data: any, params: any) => {
              return handleRenderEmailEvents(params?.email_events?.AFTER_GSTR);
            },
          },
        },
      ],
    },
  ];

  useEffect(() => {
    fetchBookingData();
  }, []);

  const fetchBookingData = async () => {
    const response = await apiGet(API_ENDPOINT_FETCH_HOTEL_BOOKING);

    if (response.status) {
      setBookingList(response.data);
    }
  };

  const renderTemplate = (htmlContent: any, data: any) => {
    const templateCompiler = Handlebars.compile(htmlContent);
    const renderedContent = templateCompiler(data);

    return renderedContent;
  };

  const getTemplateDynamicData = (data: any) => {
    let tempData: any = {
      invoice_upload_link: `http://localhost:3000/hotel_booking/upload?ref=${"tessToken"}`,
      bcd_logo:
        "https://www.bcdgroup.com/wp-content/uploads/2019/07/BCD-Travel-Logo-300x109.png",
      novartis_logo:
        "https://static.wixstatic.com/media/7b0ae8_8f33b9fa9a7640078ede15059a3193d6~mv2.png/v1/fill/w_560,h_198,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/7b0ae8_8f33b9fa9a7640078ede15059a3193d6~mv2.png",
      subject: "⏰ GST Details for generating invoice",
      acknowledge_mail_url: `https://stage-apiplatform.finkraftai.com/api/webhook/sendgrid/email/ack/${"tessToken"}`,
      upload_invoice_url: `https://hotel.finkraftai.com/hotel_booking/upload?ref=${"tessToken"}`,
      //@ts-ignore
      booking_activity: hotelBookingActivityType[data?.status || "PENDING"],
      entity: {
        name: data?.gstin_detail[0].company_name,
        gstin: data?.gstin_detail[0].gstin,
        address: data?.gstin_detail[0].address,
      },
      hotel_details: {
        hotel_name: data?.booking_data.segments.title,
      },
      booking_details: {
        confirmation_number: data?.booking_data.segments.confirmationNumber,
      },
      travelinfo: {
        travelers: [],
        contact_persons: "Ambuj",

        travelers_all_string: "",
        hotel: [
          {
            confirmationNumber: data?.booking_data?.segments.confirmationNumber,
            name: data?.booking_data?.segments.title,
            property_code: data?.booking_data?.segments.property.code,
            address: `${data?.booking_data?.segments.property.address.address1} ${data?.booking_data?.segments.property.address.city.name} ${data?.booking_data?.segments.property.address.region.code}  ${data?.booking_data?.segments.property.address.postalCode}`,
          },
        ],
        checkin_date: moment(
          data?.booking_data.tripDetails.tripStartDateTime
        ).format("DD/MM/YYYY"),
        booking_date: moment(
          data?.booking_data.tripDetails.bookingDateTime
        ).format("DD/MM/YYYY"),
        checkout_date: moment(
          data?.booking_data.tripDetails.tripEndDateTime
        ).format("DD/MM/YYYY"),
      },
    };

    data?.booking_data.travelers.forEach((traveler: any, key: any) => {
      tempData.travelinfo.travelers.push({
        name: `${traveler.name.middleName} ${traveler.name.firstName} ${traveler.name.lastName}`,
        id: key + 1,
      });
    });

    console.log("template data:", data, tempData);

    return tempData;
  };

  const gridRef = useRef<any>(null);

  const handleExportTableData = () => {
    if (gridRef.current && gridRef.current.exportDataToExcel) {
      // Call the child component's function
      gridRef.current.exportDataToExcel();
    }
  };

  return (
    <div className="HotelBookingView">
      <div className="HeaderContainer">
        <PageHeader
          title="Hotel Email Reports"
          noAuth
          rightActions={
            <div>
              <Button
                type="primary"
                size="small"
                icon={<ExportOutlined />}
                onClick={handleExportTableData}
                // style={{ marginRight: 12 }}
              >
                Export
              </Button>
            </div>
          }
        />
      </div>
      <div className="TableContainer">
        <div
          style={{
            height: "100%",
            width: "100%",
            flex: 1,
          }}
        >
          <AgTableClient
            // @ts-ignore
            rowData={bookingList}
            columnDefs={initialColDef}
            groupPanel
            // autoResize
            // autoGroupColumnDef={["Vendor Name"]}
            showStatusBar
            ref={gridRef}
          />
        </div>
      </div>
      <Modal
        open={showModal}
        onCancel={() => setShowModal(false)}
        footer={false}
        closable={false}
      >
        <div
          style={{
            height: 260,
            width: "100%",
            display: "flex",
            alignItems: "flex-start",
            flexDirection: "column",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src={UpgradeIcon}
              alt="UpgradeIcon"
              style={{ width: 36, marginRight: 8 }}
            />
            <Typography
              variant="h5"
              style={{ color: colorPicker("neutral.900") }}
            >
              Upgrade
            </Typography>
          </div>
          <Typography
            variant="xs"
            style={{ color: colorPicker("neutral.600"), marginTop: 12 }}
          >
            Upgrade for advanced features and superior support. Contact our
            sales team at Finkraft to find the best plan for you.
          </Typography>
          <div style={{ marginTop: 24 }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: 8,
              }}
            >
              <CheckCircleFilled
                style={{ color: colorPicker("primary.700"), marginRight: 6 }}
              />
              <Typography variant="caption">
                Unlimited Invoice Download
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: 8,
              }}
            >
              <CheckCircleFilled
                style={{ color: colorPicker("primary.700"), marginRight: 6 }}
              />
              <Typography variant="caption">Advance Reporting</Typography>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: 8,
              }}
            >
              <CheckCircleFilled
                style={{ color: colorPicker("primary.700"), marginRight: 6 }}
              />
              <Typography variant="caption">Priority Support</Typography>
            </div>
          </div>
          <Button type="primary" style={{ width: "100%", marginTop: 32 }}>
            Contact Sales
          </Button>
        </div>
      </Modal>

      {contextHolder}
    </div>
  );
}
