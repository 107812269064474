import { ConfigProvider } from "antd";
import Entry from "./Entry";
import { BrowserRouter as Router } from "react-router-dom";
import "theme/index.scss";
import "./RootApp.scss";
import { IntlProvider } from "react-intl";
import { RecoilRoot } from "recoil";
// import RecoilizeDebugger from "recoilize";

function App() {
  const defaultData = {
    colorPrimary: "#0A8394", //2575dc
  };

  return (
    <IntlProvider locale="en" defaultLocale="en">
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: defaultData.colorPrimary,
          },
        }}
      >
        <RecoilRoot>
          {/* <RecoilizeDebugger /> */}

          <Router>
            <Entry />
          </Router>
        </RecoilRoot>
      </ConfigProvider>
    </IntlProvider>
  );
}

export default App;
