import Typography from "app/shared/Typography";
import { colorPicker } from "app/utils/color.helper";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import { useState } from "react";
import AppIcon from "static/images/finkraftLogoSort.png";

export default function OnboardingHelpCenter(props: any) {
  const [showModal, setShowModal] = useState(false);
  return (
    <div>
      <Typography
        variant="caption"
        style={{
          color: colorPicker(props.color || "primary.700"),
          cursor: "pointer",
        }}
        onClick={() => setShowModal(true)}
      >
        <InfoCircleOutlined style={{ marginRight: 6 }} />
        {props.title || "Not having details?"}
      </Typography>
      {props.noDescription ? null : (
        <Typography variant="xs" style={{ color: colorPicker("neutral.700") }}>
          Invite someone who has the necessary integrations{" "}
        </Typography>
      )}

      <Modal
        open={showModal}
        footer={false}
        onCancel={() => setShowModal(false)}
        closeIcon={false}
      >
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: 24,
            flexDirection: "column",
          }}
        >
          <img src={AppIcon} style={{ width: 38, marginBottom: 24 }} />

          <Typography
            variant="caption"
            style={{ color: colorPicker("neutral.800") }}
          >
            <InfoCircleOutlined style={{ marginRight: 6 }} />
            Contact Make my trip accopuntant
          </Typography>

          <Typography
            variant="caption"
            style={{
              color: colorPicker("neutral.800"),
              marginTop: 12,
              marginBottom: 12,
            }}
          >
            Or
          </Typography>

          <Typography
            variant="caption"
            style={{ color: colorPicker("primary.700") }}
          >
            Call 917895364466 for assistance
          </Typography>
        </div>
      </Modal>
    </div>
  );
}
