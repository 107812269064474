import { Outlet } from "react-router";
import AppLogo from "static/images/FinLogo.png";
import "./Auth.scss";
import Typography from "app/shared/Typography";
import { colorPicker } from "app/utils/color.helper";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { Avatar } from "antd";
import DoubleQuotes from "static/images/DoubleQuotes.svg";
export default function Auth(props: any) {
  return (
    <div className="Auth">
      <div className="Container">
        <div className="LeftContainer">
          <div className="TopLogo">
            <img alt="AppLogo" src={AppLogo} />
            <div className="MiniDivder" />
          </div>
          <div className="MetaInfo">
            <Typography
              style={{
                fontSize: 40,
                color: colorPicker("neutral.950"),
                lineHeight: "44px",
              }}
            >
              Start your <br /> journey with us
            </Typography>

            {/* <Typography
              style={{
                fontSize: 28,
                color: colorPicker("neutral.800"),
                marginTop: 32,
              }}
            >
              Seamless onboarding
            </Typography> */}

            <Typography
              style={{
                color: colorPicker("neutral.800"),
                marginTop: 6,
              }}
            >
              Discover the oasis of reconciliation, where efficiency and
              precision converge in perfect equilibrium.
            </Typography>
          </div>
          <div className="CarouselContainer">
            <div className="CarouselCard">
              <img
                src={DoubleQuotes}
                alt="doubleQuote"
                className="quoteImage"
              />
              <Typography
                style={{ color: colorPicker("neutral.900"), marginTop: 12 }}
              >
                Krafting a welcoming experience that leaves no room for
                confusion.
              </Typography>

              <div className="UserInfo">
                <Avatar
                  size={52}
                  src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT0R5na2w29xsGM_nEpE1eDCJBvyAW3weOhuEirffnyiA&s"
                  style={{
                    borderRadius: 18,
                  }}
                />
                <div className="UserDetails">
                  <Typography>Rohit Anand</Typography>
                  <Typography
                    variant="caption"
                    style={{ color: colorPicker("neutral.700") }}
                  >
                    Founder of Finkraft.ai
                  </Typography>
                </div>
              </div>
            </div>
            {/* <Carousel>
              <div className="">
                <img src="assets/1.jpeg" />
                <p className="legend">Legend 1</p>
              </div>
              <div>
                <img src="assets/2.jpeg" />
                <p className="legend">Legend 2</p>
              </div>
              <div>
                <img src="assets/3.jpeg" />
                <p className="legend">Legend 3</p>
              </div>
            </Carousel> */}
          </div>
        </div>
        <div className="RightContainer">
          <Outlet />
        </div>
      </div>
    </div>
  );
}
