import { Button, Divider, Modal } from "antd";
import TextField from "app/shared/TextField";
import Typography from "app/shared/Typography";
import { colorPicker } from "app/utils/color.helper";
import { QuestionCircleOutlined, PlusOutlined } from "@ant-design/icons";
import "../OnboardingInviteUser.scss";
import OnboardingHelpCenter from "../../OnboardingHelpCenter";
import { useEffect, useState } from "react";
import AddGstinWidget from "../../AddGstinWidget";
import { useNavigate } from "react-router-dom";
import ValidateCredentials from "app/scenes/Onboarding/components/ValidateCredentials";
import SitRelax from "app/scenes/Onboarding/components/SitRelax";
import AddBusiness from "app/scenes/Onboarding/components/AddBusiness";
import { apiGet } from "app/services/apiServices";
import { API_ENDPOOINT_ONBOARDING_INFO } from "app/scenes/OnboardingV2/onboardingv2.constants";
import { useRecoilState } from "recoil";
import { onboardInfo } from "app/config/States/users";
export default function OnboardingAddGstin(props: any) {
  const [showModal, setShowModal] = useState("");
  const [gstins, setGstins] = useState<any[]>([]);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [GSTAdded, setGSTAdded] = useState(false);
  const [GSTSubmit, setGSTSubmit] = useState(false);
  const [userOnboardInfo, setOnboardInfo] = useRecoilState<any>(onboardInfo);

  const navigate = useNavigate();

  const getOnboardingInfo = async () => {
    let workspace = localStorage.getItem("currentWorkspace");
    const response = await apiGet(
      API_ENDPOOINT_ONBOARDING_INFO + "?workspace=" + workspace
    );
    if (response.status) {
      setOnboardInfo({
        currentWorkspace: response?.data?.workspace?.id,
        currentWorkspaceName: response?.data?.workspace?.name,
        workspaceMeta: response?.data?.workspace?.meta,
      });
    } else {
      navigate("/auth/login");
    }
  };

  useEffect(() => {
    getOnboardingInfo();
  }, []);

  const handleAddGstin = () => {
    // setGSTAdded(true);
    setGstins([{ id: 1, username: username, password: password }]);
    setShowModal("CONFIRM_GSTIN");
  };

  const handlePreviewGSTIN = (gstData: any) => {
    setGSTAdded(true);
    setGstins(gstData.data);
  };

  const handleReviewGSTIN = (gstins: any) => {
    setGstins(gstins);
    setGSTAdded(true);
    console.log("coming here:", gstins);
  };
  return (
    <div className="LeftSection">
      <Typography variant="h6" style={{ color: colorPicker("neutral.800") }}>
        Enter your{" "}
        <b style={{ color: colorPicker("primary.700") }}>GSTIN Details </b> to
        check missing invoices
      </Typography>
      <div className="FormGroup">
        <div className="FieldContainer">
          <TextField
            placeholder="Username"
            label="Username"
            labelColor={colorPicker("neutral.800")}
            height={42}
            onChange={(e: any) => setUsername(e.target.value)}
          />
        </div>

        <div className="FieldContainer">
          <TextField
            placeholder="Password"
            label="Password"
            labelColor={colorPicker("neutral.800")}
            height={42}
            onChange={(e: any) => setPassword(e.target.value)}
          />
        </div>

        <div className="ActionContainer">
          <Button
            style={{ width: "49%" }}
            size="large"
            icon={<PlusOutlined />}
            onClick={() => setShowModal("BULK_GSTIN")}
          >
            Upload Multiple GSTINs
          </Button>
          <Button
            style={{ width: "49%" }}
            type="primary"
            size="large"
            onClick={handleAddGstin}
          >
            Submit
          </Button>
        </div>
        <Divider style={{ marginTop: 32 }} />

        <OnboardingHelpCenter />
        <Button
          style={{ width: "100%", marginTop: 24 }}
          size="large"
          icon={<PlusOutlined />}
          onClick={() =>
            navigate("/onboarding2/invite_user", {
              state: { tokenInfo: props?.tokenInfo },
            })
          }
        >
          Invite User
        </Button>

        <div style={{ marginTop: 32 }}>
          <OnboardingHelpCenter
            title="Help Center"
            color="neutral.700"
            noDescription
          />
          {/* <Typography
            variant="xs"
            style={{ color: colorPicker("neutral.700"), cursor: "pointer" }}
          >
            <QuestionCircleOutlined style={{ marginRight: 6 }} />
            Help Center
          </Typography> */}
        </div>
      </div>

      <Modal
        title={
          GSTSubmit
            ? ""
            : showModal === "CONFIRM_GSTIN"
            ? "Confirm Gstins and password"
            : "Upload Bulk Gstins"
        }
        width={800}
        closeIcon={!GSTSubmit}
        open={showModal !== ""}
        onCancel={() => setShowModal("")}
        footer={false}
      >
        {showModal === "CONFIRM_GSTIN" && !GSTAdded ? (
          <AddGstinWidget gstins={gstins} handleAddGST={handleReviewGSTIN} />
        ) : GSTAdded ? (
          <div style={{ height: 300 }}>
            <ValidateCredentials
              tableData={{ data: gstins, type: "GSTIN" }}
              goBack={() => setGSTAdded(false)}
              onSuccess={() => navigate("/onboarding2/landing")}
            />
          </div>
        ) : GSTSubmit ? (
          <SitRelax />
        ) : (
          <AddBusiness
            type={showModal}
            gstins={gstins}
            goNext={handlePreviewGSTIN}
          />
        )}
      </Modal>
    </div>
  );
}
