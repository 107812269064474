import ProtectedRoute from "app/utils/ProtectedRoute";
import Onboarding from "./OnboardingV2";
import JoinWorkspace from "./components/JoinWorkspace";
import BookingDataPreview from "./components/BookingDataPreview";
import OnboardingInviteUser from "./components/OnboardingInviteUser";
import OnboardingLandingDash from "./components/OnboardingLandingDash";
import OnboardingAddGSTCred from "./components/OnboardingAddGSTCred";
import LandingDataPage from "./components/LandingDataPage";

//eslint-disable-next-line
export default {
  path: "/onboarding2/",
  element: <Onboarding />,
  strict: true,
  children: [
    {
      path: "/onboarding2/dataview",
      element: (
        <ProtectedRoute>
          <LandingDataPage />
        </ProtectedRoute>
      ),
      exact: true,
    },
    {
      path: "/onboarding2/join/:inviteCode",
      element: <JoinWorkspace />,
      exact: true,
    },
    {
      path: "/onboarding2/bookingpreview",
      element: (
        <ProtectedRoute>
          <BookingDataPreview />
        </ProtectedRoute>
      ),
      exact: true,
    },
    {
      path: "/onboarding2/invite_user",
      element: (
        <ProtectedRoute>
          <OnboardingInviteUser />
        </ProtectedRoute>
      ),
      exact: true,
    },
    {
      path: "/onboarding2/creds",
      element: (
        <ProtectedRoute>
          <OnboardingAddGSTCred />
        </ProtectedRoute>
      ),
      exact: true,
    },
    {
      path: "/onboarding2/landing",
      element: <OnboardingLandingDash />,
      exact: true,
    },
  ],
};
