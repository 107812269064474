import { useEffect, useState } from "react";
import OnboardingHeader from "./components/OnboardingHeader";
import "./OnboardingV2.scss";
import { Outlet } from "react-router-dom";
import { onboardInfo } from "app/config/States/users";

export default function OnboardingV2(props) {
  return (
    <div className="OnboardingV2">
      <Outlet />
      {/* <div className="HeaderContainer">
        <OnboardingHeader />
      </div>
      <div className="ContentBody">
        <Outlet />
      </div> */}
    </div>
  );
}
